import {UIModelFieldSelectItem, UIModelFieldType, ValidatedValue} from "@aatdev/common-types";
import {DialerCallingListSourceTypes} from "@aatdev/dialer-types";
import {Card, CardContent, Grid, IconButton, makeStyles} from "@material-ui/core";
import {Delete} from "@material-ui/icons";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {createValidateValue} from "../../../utils/ValidatedValueUtils";
import {DataFormProps} from "../../DataEditor/DataForm/DataFormTypes";
import DataInput from "../../DataEditor/DataForm/DataInput";
import ListEditor, {ListEditorComponentType} from "../../Utils/ListEditor";

type Props = {} & DataFormProps;

type DialerCallingListSourceValidated = {
    type: ValidatedValue<DialerCallingListSourceTypes>
    directory: ValidatedValue<string>
}

type DialerCallingListSourcesValidated = {
    list: DialerCallingListSourceValidated[]
}

const CallingListSources: FunctionComponent<Props> = (props: Props) => {
    const {onChange, fieldPrefix, element} = props;
    const {data, permissions} = element;
    const tabData: DialerCallingListSourcesValidated = data[fieldPrefix] || {
        list: []
    };

    const getDefaultRow = () => ({
        type: createValidateValue(""),
        directory: createValidateValue("")
    });

    return <ListEditor
        defaultListValue={getDefaultRow()}
        listFieldName={"list"}
        listEditorComponent={ItemEditor}
        data={tabData}
        readonly={!permissions.edit}
        onChange={onChange}
        helpPrefix={[element.uiModel.meta.collection, fieldPrefix]}
    />;
}

const ItemEditor: FunctionComponent<ListEditorComponentType> = (props) => {
    const {row, onChange, onDelete, readonly, helpPrefix = []} = props;
    const {t} = useTranslation();
    const classes = useStyles();

    const getTypeOptions = () => {
        return Object.keys(DialerCallingListSourceTypes).reduce((pv: Record<string, UIModelFieldSelectItem>, cv) => {
            pv[cv] = {
                label: t(`tables:calling_list.tabs.sources.fields.type.values.${cv}`),
            };
            return pv;
        }, {});
    }

    const getBodyByType = () => {
        if (row.type.value === DialerCallingListSourceTypes.directory) {
            return <Grid item xs={8}>
                <DataInput
                    config={{
                        type: UIModelFieldType.text,
                        title: t("tables:calling_list.tabs.sources.fields.directory.title"),
                        field: "directory"
                    }}
                    constrains={"notEmpty"}
                    fullWidth={true}
                    value={row.directory}
                    disabled={readonly}
                    onChange={(value) => onChange(["directory"], value)}
                    helpId={[...helpPrefix, "directory"]}
                />
            </Grid>
        }
    }

    return <Card className={classes.details}>
        <CardContent style={{flex: 1}}>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <DataInput
                        config={{
                            type: UIModelFieldType.select,
                            title: t("tables:calling_list.tabs.sources.fields.type.title"),
                            field: "type",
                            items: getTypeOptions()
                        }}
                        constrains={"notEmpty"}
                        value={row.type}
                        disabled={readonly}
                        fullWidth={true}
                        onChange={(value) => onChange(["type"], value)}
                        helpId={[...helpPrefix, "type"]}
                    />
                </Grid>
                {getBodyByType()}
                <Grid item xs={1}>
                    <DataInput
                        config={{
                            type: UIModelFieldType.boolean,
                            title: t("tables:calling_list.tabs.sources.fields.enabled.title"),
                            field: "enabled"
                        }}
                        fullWidth={true}
                        value={row.enabled}
                        disabled={readonly}
                        onChange={(value) => onChange(["enabled"], value)}
                        helpId={[...helpPrefix, "enabled"]}
                    />
                </Grid>
            </Grid>
        </CardContent>
        <span>
            <IconButton color={"secondary"} onClick={onDelete} disabled={readonly}>
                <Delete/>
            </IconButton>
        </span>
    </Card>;
}

const useStyles = makeStyles({
    details: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        marginTop: "1em"
    }
});

export default CallingListSources;
