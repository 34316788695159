"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DialerStrategyMoveToNextGroupType = exports.DialerStrategyWalkType = exports.DialerStrategyPhoneRuleType = void 0;
var DialerStrategyPhoneRuleType;
(function (DialerStrategyPhoneRuleType) {
    DialerStrategyPhoneRuleType["error"] = "error";
    DialerStrategyPhoneRuleType["busy"] = "busy";
    DialerStrategyPhoneRuleType["noanswer"] = "noanswer";
    DialerStrategyPhoneRuleType["not_available"] = "not_available";
})(DialerStrategyPhoneRuleType = exports.DialerStrategyPhoneRuleType || (exports.DialerStrategyPhoneRuleType = {}));
var DialerStrategyWalkType;
(function (DialerStrategyWalkType) {
    DialerStrategyWalkType["serial"] = "serial";
    DialerStrategyWalkType["round_robin"] = "round_robin";
})(DialerStrategyWalkType = exports.DialerStrategyWalkType || (exports.DialerStrategyWalkType = {}));
var DialerStrategyMoveToNextGroupType;
(function (DialerStrategyMoveToNextGroupType) {
    DialerStrategyMoveToNextGroupType["hour"] = "hour";
    DialerStrategyMoveToNextGroupType["day"] = "day";
    DialerStrategyMoveToNextGroupType["week"] = "week";
    DialerStrategyMoveToNextGroupType["month"] = "month";
})(DialerStrategyMoveToNextGroupType = exports.DialerStrategyMoveToNextGroupType || (exports.DialerStrategyMoveToNextGroupType = {}));
