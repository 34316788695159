import React, {FunctionComponent} from 'react';

type Props = {};

const NotFound: FunctionComponent<Props> = (props: React.PropsWithChildren<Props>) => {
    return (
        <div>
            {props.children}
        </div>
    );
}

export default NotFound;
