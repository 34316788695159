import {DBElement} from "@aatdev/common-types";
import {DialerCollections, DialerSelectionModel, DialerUploadedListPhoneModel} from "@aatdev/dialer-types";
import {FunctionComponent} from "react";
import PreconfiguredDataTableChain from "../../../CommonDataTable/PreconfiguredDataTable/PreconfiguredDataTableChain";
import {getTitle} from "../../../CommonDataTable/PreconfiguredDataTable/PreconfiguredDataTableUtils";
import CallListTable from "../CallListTable";
import UploadedPhoneListTable from "../UploadedPhoneListTable";

type Props = {
    prefixTableId: string
    selection: DialerSelectionModel
};

const SelectionPhoneListChain: FunctionComponent<Props> = (props) => {
    const {selection, prefixTableId} = props;

    return <PreconfiguredDataTableChain
        title={getTitle(DialerCollections.uploaded_list_phone, selection.name)}
        component={UploadedPhoneListTable}
        menuTableId={prefixTableId}
        props={{
            tableId: `${DialerCollections.uploaded_list_phone}:${selection._id}`,
            editable: true,
            params: {
                selection
            }
        }}
        onNext={(el: DBElement) => {
            const contact = el as DialerUploadedListPhoneModel;
            return {
                title: getTitle(DialerCollections.call, el.name),
                component: CallListTable,
                menuTableId: prefixTableId,
                props: {
                    tableId: `${DialerCollections.call}:${selection._id}:${contact._id}`,
                    params: {
                        contact
                    }
                },
            }
        }
        }
    />
}

export default SelectionPhoneListChain;
