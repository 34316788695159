import {ElementWithDeps} from "@aatdev/common-types";
import {Button, Divider} from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {alpha, createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import moment from "moment-timezone";
import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {BaseDbCollection} from "../../data/BaseDbCollection";
import {ProjectModel} from "../../data/models/ProjectModel";
import LocalStoreService from "../../services/LocalStoreService";
import {setCurrentProjectThunk} from "../../store/actions/DataActions";
import {AppState} from "../../store/StoreTypes";
import CommonTableSelect from "../CommonDataTable/CommonTableSelect";
import ProjectStatsToolbar from "../Custom/ToolbarItems/ProjectStatsToolbar";
import AboutDialog from "../Dialogs/AboutDialog";
import {WhiteLabelContext} from "../Utils/WhiteLabelProvider";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuButton: {
            marginRight: theme.spacing(2),
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: alpha(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: alpha(theme.palette.common.white, 0.25),
            },
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(3),
                width: 'auto',
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '40ch',
            },
        },
        logo: {
            height: "4em",
            marginRight: "1em"
        },
        timezone: {
            marginRight: "1em",
            fontSize: "1.2em",
            fontWeight: "bold"
        }
    }),
);

type Props = {
    onOpenDrawer: () => void,
    onLogout: () => void
}

const MainToolbar: FunctionComponent<Props> = (props: Props) => {
    const {onOpenDrawer, onLogout} = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openProject, setOpenProject] = useState(false);
    const [aboutDialogOpen, setAboutDialogOpen] = useState(false);
    const currentProject = useSelector((state: AppState) => state.db.currentProject);
    const user = useSelector((state: AppState) => state.general.user);
    const dispatch = useDispatch();
    const whiteLabel = useContext(WhiteLabelContext);
    const isMenuOpen = Boolean(anchorEl);
    const {t} = useTranslation();

    useEffect(() => {
        if (user) {
            const projectId = LocalStoreService.get('current-project-id');
            if (!projectId || (currentProject && currentProject._id !== projectId)) {
                setOpenProject(true);
            } else {
                setCurrentProject(projectId);
            }
        }
    }, [user]);

    const setCurrentProject = (id: string) => {
        if (!currentProject || currentProject._id !== id) {
            dispatch(setCurrentProjectThunk(id, project => {
                if (!project) {
                    setOpenProject(true);
                }
            }));
        }
    }

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleSelectProject = (project: ElementWithDeps<ProjectModel>) => {
        setOpenProject(false);
        LocalStoreService.set('current-project-id', project.element._id);
        setCurrentProject(project.element._id);
    }

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={menuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMenuOpen}
            onClose={handleMenuClose}>
            <MenuItem onClick={() => setAboutDialogOpen(true)}>{t('common:toolbar.profile.about')}</MenuItem>
            <Divider/>
            <MenuItem onClick={onLogout}>{t('common:toolbar.profile.logout')}</MenuItem>
        </Menu>
    );

    return (
        <div>
            <AboutDialog open={aboutDialogOpen} onClose={() => setAboutDialogOpen(false)}/>
            <CommonTableSelect
                open={openProject}
                title={t('common:toolbar.project.table')}
                collection={BaseDbCollection.project}
                onSelect={handleSelectProject}
                onClose={() => setOpenProject(false)}
                notClosable={true}
            />
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        onClick={onOpenDrawer}>
                        <MenuIcon/>
                    </IconButton>
                    <img src={whiteLabel?.icons?.logo} className={classes.logo}/>
                    <Typography variant="h6" noWrap>
                        {t(whiteLabel?.text?.toolbar || 'common:toolbar.title', 'Dial')}
                    </Typography>
                    <ProjectStatsToolbar/>
                    <div className={classes.timezone}>
                        {moment(new Date()).format("DD.MM.YYYY HH:mm:ss Z") + " " + moment.tz.zone(moment.tz.guess())?.abbr(new Date().getTime())}
                    </div>
                    {/*<div className={classes.grow}/>*/}
                    <Button variant={"contained"} color={"secondary"} onClick={() => setOpenProject(true)}>
                        {currentProject?.name ? currentProject.name : t('common:toolbar.project.button')}
                    </Button>
                    <div>
                        <IconButton edge="end"
                                    aria-controls={menuId}
                                    aria-haspopup="true"
                                    onClick={handleProfileMenuOpen}
                                    color="inherit">
                            <AccountCircle/>
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMenu}
        </div>
    );
}

export default MainToolbar;
