import DateFnsUtils from "@date-io/date-fns";
import {AppointmentForm as AppointmentFormBase} from "@devexpress/dx-react-scheduler";
import {DateTimePicker} from "@material-ui/pickers/DateTimePicker";
import MuiPickersUtilsProvider from "@material-ui/pickers/MuiPickersUtilsProvider";
import moment from "moment";
import React, {FunctionComponent} from "react";
import {getDateFsLocale} from "../../utils/DateUtils";

type Props = AppointmentFormBase.DateEditorProps;

const SchedulerDateEditor: FunctionComponent<Props> = (props) => {
    const {
        onValueChange,
        value,
        readOnly,
        locale,
        excludeTime,
        ...restProps
    } = props;

    const memoizedChangeHandler = React.useCallback(
        nextDate => {
            if (nextDate) {
                if (moment.isMoment(nextDate)) {
                    onValueChange(nextDate.toDate());
                } else {
                    onValueChange(nextDate)
                }
            }
        }, [onValueChange],
    );

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getDateFsLocale()}>
            <DateTimePicker
                inputVariant="outlined"
                ampm={false}
                disabled={readOnly}
                value={value}
                format="dd/MM/yyyy HH:mm"
                onChange={memoizedChangeHandler}
                {...restProps}
            />
        </MuiPickersUtilsProvider>
    );
}

export default SchedulerDateEditor;
