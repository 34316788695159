import {getMessageProxy} from "../../utils/I18nUtils";

export const editRecurrentMenuMessages = () => getMessageProxy("common:schedule.edit",
    [
            /** Text for the 'Current appointment' option. */
            "current",
            /** Text for the 'Current and following appointments' option. */
            "currentAndFollowing",
            /** Text for the 'All appointments' option. */
            "all",
            /** The menu's title that should be displayed when an appointment is being edited. */
            "menuEditingTitle",
            /** The menu's title that should be displayed when an appointment is being deleted. */
            "menuDeletingTitle",
            /** The Cancel button's text. */
            "cancelButton",
            /** The OK button's text. */
            "commitButton"
    ]);

export const appointmentFormMessages = () => getMessageProxy("common:schedule.form",
    [
            "allDayLabel",
            /** The "Title" editor's label text. */
            "titleLabel",
            /** The commit button's text. */
            "commitCommand",
            /** The "More Information" editor’s label text. */
            "moreInformationLabel",
            /** The "Repeat" editor’s label text. */
            "repeatLabel",
            /** The "Notes" editor’s label text. */
            "notesLabel",
            /** The "Never" label text. */
            "never",
            /** The "Daily" label text. */
            "daily",
            /** The "Weekly" label text. */
            "weekly",
            /** The "Monthly" label text. */
            "monthly",
            /** The "Yearly" label text. */
            "yearly",
            /** The "Repeat every" label text. */
            "repeatEveryLabel",
            /** The "day(s)" label text. */
            "daysLabel",
            /** The "End repeat" label text. */
            "endRepeatLabel",
            /** The "On" label text. */
            "onLabel",
            /** The "After" label text. */
            "afterLabel",
            /** The "Occurrences" label text. */
            "occurrencesLabel",
            /** The "week(s) on:" label text. */
            "weeksOnLabel",
            /** The "month(s)" label text. */
            "monthsLabel",
            /** The "of every month" label text. */
            "ofEveryMonthLabel",
            /** The "The" label text. */
            "theLabel",
            /** The "First" label text. */
            "firstLabel",
            /** The "Second" label text. */
            "secondLabel",
            /** The "Third" label text. */
            "thirdLabel",
            /** The "Fourth" label text. */
            "fourthLabel",
            /** The "Last" label text. */
            "lastLabel",
            /** The "year(s)" label text. */
            "yearsLabel",
            /** The "of" label text. */
            "ofLabel",
            /** The "Every" label text. */
            "everyLabel",
            /** The "Details" label text. */
            "detailsLabel",
    ]);

export const confirmationDialogMessages = () => getMessageProxy("common:schedule.confirm_dialog",
    [
            /** The Discard button's text. */
            "discardButton",
            /** The Delete button's text. */
            "deleteButton",
            /** The Cancel button's text. */
            "cancelButton",
            /** The delete confirmation dialog text. */
            "confirmDeleteMessage",
            /** The cancel confirmation dialog text. */
            "confirmCancelMessage",
    ]);
