import {SnackbarKey} from "notistack";
import React from "react";
import {NotifierClose} from "../components/Notifier/NotifierClose";
import {fixedT} from "../i18n";
import {enqueueSnackbar} from "../store/actions/NotiferActions";
import {NotifierStateProps} from "../store/actions/NotiferActionTypes";
import {APThunkDispatch} from "../store/StoreTypes";

export type NotifierType = "default" | "error" | "success" | "warning" | "info" | undefined;
const t = fixedT();

const duration = (type: NotifierType) => {
    if (type === 'error') {
        return 5000;
    }
    return 2000;
}
const action: any = (key: SnackbarKey) => {
    return (<NotifierClose msgKey={key}/>);
}

export const notify = (dispatch: any, message: any, type: NotifierType) => {
    let msg = message.toString();
    if (message?.response?.data?.errors) {
        msg = message.response.data.errors;
    }
    dispatch(enqueueSnackbar({
        message: t(msg, msg),
        key: new Date().getTime() + Math.random(),
        options: {
            variant: type,
            action: action,
            preventDuplicate: true,
            autoHideDuration: duration(type)
        }
    }))
}

export const notiferStateProps = (dispatch: APThunkDispatch): NotifierStateProps => {
    return {
        notify: (message, type = 'info') => notify(dispatch, message, type)
    }
}
