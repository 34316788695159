import {WhiteLabelType} from "@aatdev/common-types";
import {createTheme} from "@material-ui/core";
import * as muiCcolors from "@material-ui/core/colors";

export const getMuiTheme = (whiteLabel: WhiteLabelType) => {
    const colors = muiCcolors as any;
    return createTheme({
        overrides: {
            MuiInputBase: {
                root: {
                    backgroundColor: "white"
                }
            }
        },
        palette: {
            primary: colors[whiteLabel.colors.primary],
            secondary: colors[whiteLabel.colors.secondary],
            text: {
                disabled: 'black'
            }
        },
    });
}
