import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, {FunctionComponent} from "react";
import {FixedSizeList} from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import {getDBElementName} from "../../utils/ModelUtils";

type Props = {
    hasNextPage: boolean
    isNextPageLoading: boolean
    items: any[]
    wrapperClassName?: string
    getOptionProps: any
    groupedOptions: any
    loadNextPage: () => void
    listboxProps: any,
    total: number
}

const noop = () => {
}

const InfiniteWrapper: FunctionComponent<Props> = (props) => {
    const {children} = props;
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"));
    const itemSize = smUp ? 36 : 48;
    const {hasNextPage, isNextPageLoading, items, loadNextPage, total, ...otherProps} = props;
    const itemCount = items.length;
    const loadMoreItems = isNextPageLoading ? noop : loadNextPage;
    const isItemLoaded = (index: number) => !hasNextPage || index < items.length;

    const renderRow = ({data, index, style}: { data: any, index: number, style: any }) => {
        return !data || index >= data.length ? (
            <div/>
        ) : (
            React.cloneElement(data[index], {
                style: {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    display: "block",
                    ...style
                },
                title: getDBElementName(data[index])
            })
        );
    };

    return (
        <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={total}
            loadMoreItems={loadMoreItems}
            minimumBatchSize={30}
            threshold={5}>
            {({onItemsRendered, ref}) => (
                <div {...otherProps}>
                    <FixedSizeList
                        style={{
                            padding: 0,
                            height: Math.min(8, itemCount) * itemSize,
                            maxHeight: "auto",
                            minWidth: 300
                        }}
                        itemData={children}
                        height={350}
                        width="100%"
                        innerElementType="ul"
                        itemSize={itemSize}
                        itemCount={total}
                        onItemsRendered={onItemsRendered}
                        ref={ref}>
                        {renderRow}
                    </FixedSizeList>
                </div>
            )}
        </InfiniteLoader>
    );
}

export default InfiniteWrapper;
