import React, {useEffect} from 'react';
import {Route, Routes} from 'react-router-dom';
import NotFound from "../NotFound/NotFound";
import {AppMenuItemsType} from "../../store/actions/DataActionTypes";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {loadModelsAction} from "../../store/actions/DataActions";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store/StoreTypes";

type OwnProps = {
    menu: AppMenuItemsType
};
type Props = OwnProps;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            boxSizing: 'border-box',
            position: 'relative',
            marginTop: '0.5em'
        },
    })
);

const AppContainer: React.FC<Props> = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = useSelector((state: AppState) => state.general.user);
    const project = useSelector((state: AppState) => state.db.currentProject);

    useEffect(() => {
        if (user) {
            dispatch(loadModelsAction(project?._id || "any"));
        }
    }, [user]);

    const currentRoutes = Object.values(props.menu).map((route, index) => {
        const Component = route.component;
        return <Route key={index} path={route.path} element={<Component/>}/>;
    });

    return (
        <div className={classes.root}>
            <Routes>
                {currentRoutes}
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </div>
    );
};

export default AppContainer;
