import {DialerCampaignCurrentCounters, DialerCampaignLimits} from "@aatdev/dialer-types";
import {Grid} from "@material-ui/core";
import _ from "lodash";
import {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import styled from 'styled-components';
import {calcPercent} from "../../../utils/MiscUtils";

type Props = {
    header: string,
    totalData: DialerCampaignCurrentCounters
}

const StyledHeader = styled(Grid)`
  ${({theme}) => `
    text-align: center;
    font-weight: bold;
    color: ${theme.palette.info.dark};
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  `}
`;

export const CallStatisticsCard: FunctionComponent<Props> = (props) => {
    const {header, totalData} = props;
    const {t} = useTranslation();
    const totalCalls = totalData?.total_calls;
    const totalContacts = totalData?.total_contacts;
    const calcPercentColumn = (field: DialerCampaignLimits) => {
        if (field.endsWith('_calls')) {
            return calcPercent(totalData?.[field], totalCalls);
        }
        if (field.endsWith('_duration')) {
            if (totalCalls && totalData?.[field]) {
                return _.round(totalData?.[field] / totalCalls);
            }
        }
        if (field === "completed_contacts") {
            return calcPercent(totalData?.[field], totalContacts);
        }
        return "0";
    }
    return <Grid container>
        <StyledHeader xs={12} item key={'header'}>{header}</StyledHeader>
        {Object.values(DialerCampaignLimits).map((key) => <>
            <Grid item xs={7}><b>{t(`tables:campaign.tabs.limits.fields.field.values.${key}`)}</b></Grid>
            <Grid item xs={3}>{_.round(totalData?.[key] || 0)}</Grid>
            <Grid item xs={2}>{calcPercentColumn(key)}</Grid>
        </>)}
    </Grid>
}

