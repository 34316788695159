import React, {FunctionComponent, useEffect, useState} from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Backdrop, createStyles, makeStyles, Theme} from "@material-ui/core";

type Props = {
    open: boolean
    delay?: number
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        width: "100vw"
    },
    indicator: {
        justifyContent: "center",
        position: "fixed",
        top: "45%"
    },
    backdrop: {
        zIndex: 10000
    }
}),)

const LoadIndicator: FunctionComponent<Props> = (props: Props) => {
    const styles = useStyles();
    const [open, setOpen] = useState(false);
    const timer = setTimeout(() => {
        setOpen(props.open);
    }, props.delay || 1000);
    useEffect(() => {
        return () => {
            clearTimeout(timer);
        }
    });
    return <Backdrop open={open} className={styles.backdrop}>
        <div className={styles.container}>
        <span className={styles.indicator}>
            <CircularProgress size={100}/>
        </span>
        </div>
    </Backdrop>
}

export default LoadIndicator;
