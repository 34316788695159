"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFileCallerURL = exports.createConsoleLogger = void 0;
function createConsoleLogger() {
    const wrapper = (level) => (message) => {
        const mod = getFileCallerURL();
        if (typeof message === "function") {
            console.log(`${level}: ${mod}: ${message()}`);
        }
        else {
            console.log(`${level}: ${mod}: ${message}`);
        }
    };
    return {
        error: wrapper("ERROR"),
        isErrorEnabled: () => true,
        warn: wrapper("WARN"),
        isWarnEnabled: () => true,
        info: wrapper("INFO"),
        isInfoEnabled: () => true,
        debug: wrapper("DEBUG"),
        isDebugEnabled: () => true,
        silly: wrapper("SILLY"),
        isSillyEnabled: () => true,
        setLevel: l => {
        }
    };
}
exports.createConsoleLogger = createConsoleLogger;
function getFileCallerURL() {
    var _a;
    const error = new Error();
    const stack = (_a = error.stack) === null || _a === void 0 ? void 0 : _a.split('\n');
    const data = stack[3];
    return data.slice(data.lastIndexOf('/') + 1, data.length - 1);
}
exports.getFileCallerURL = getFileCallerURL;
