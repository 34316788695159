import * as Icons from "@material-ui/icons";
import React from "react";
import {CustomTableActionType} from "../CommonDataTable/CommonDataTableTypes";

export const actionUtils = (name: string) => {
    // @ts-ignore
    const Icon = Icons[name] as any;
    if (Icon) return <Icon/>;
    return null;
}

export const renderActionButtons = (actions: CustomTableActionType[], align: 'left' | 'right') => {
    return actions.filter(e => e.buttonPosition() === align).map((e, i) => {
        const Comp = e.renderButton();
        return <Comp key={i}/>;
    })
}

export const renderActionCode = (actions: CustomTableActionType[]) => {
    return actions.map((e, i) => {
        const Comp = e.renderCode();
        return <Comp key={i}/>;
    })
}
