import {UIModelFieldType, ValidatedValue} from "@aatdev/common-types";
import {replaceAccordingToRules} from "@aatdev/dialer-utils";
import {Card, CardContent, Grid, IconButton, makeStyles} from "@material-ui/core";
import {Delete} from "@material-ui/icons";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {v4} from "uuid";
import {createValidateValue, deleteValidatedData} from "../../../utils/ValidatedValueUtils";
import {DataFormProps} from "../../DataEditor/DataForm/DataFormTypes";
import DataInput from "../../DataEditor/DataForm/DataInput";
import ListEditor, {ListEditorComponentType} from "../../Utils/ListEditor";

type Props = {} & DataFormProps;

export type DialerCallingListNormalizeRulesValidated = {
    rules: {
        id: ValidatedValue<string>
        rule: ValidatedValue<string>
        replace: ValidatedValue<string>
        example: ValidatedValue<string>
    }[]
}

const UploadListNormalizeRules: FunctionComponent<Props> = (props: Props) => {
    const {onChange, fieldPrefix, element} = props;
    const {data, permissions} = element;
    const tabData: DialerCallingListNormalizeRulesValidated = data[fieldPrefix] || {
        rules: []
    };

    const getDefaultRow = () => ({
        id: createValidateValue(v4(), true),
        rule: createValidateValue(""),
        replace: createValidateValue(""),
        example: createValidateValue(""),
    });

    return <ListEditor
        defaultListValue={getDefaultRow()}
        listFieldName={"rules"}
        listEditorComponent={Editor}
        data={tabData}
        readonly={!permissions.edit}
        onChange={onChange}
    />;
}

const Editor: FunctionComponent<ListEditorComponentType> = (props) => {
    const {onDelete, readonly, row, onChange} = props;
    const {t} = useTranslation();
    const classes = useStyles();
    let result = "";
    try {
        result = replaceAccordingToRules([deleteValidatedData(row)], row.example.value);
    } catch (e: any) {
        result = e.message;
    }
    return <Card className={classes.details}>
        <CardContent style={{flex: 1}}>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <DataInput
                        config={{
                            type: UIModelFieldType.text,
                            title: t("tables:calling_list.tabs.normalize_rules.fields.rule.title"),
                            field: "rule"
                        }}
                        fullWidth={true}
                        constrains={"notEmpty"}
                        value={row.rule}
                        disabled={readonly}
                        onChange={(value) => onChange(["rule"], value)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <DataInput
                        config={{
                            type: UIModelFieldType.text,
                            title: t("tables:calling_list.tabs.normalize_rules.fields.replace.title"),
                            field: "replace"
                        }}
                        fullWidth={true}
                        constrains={"notEmpty"}
                        value={row.replace}
                        disabled={readonly}
                        onChange={(value) => onChange(["replace"], value)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <DataInput
                        config={{
                            type: UIModelFieldType.text,
                            title: t("tables:calling_list.tabs.normalize_rules.fields.example.title"),
                            field: "example"
                        }}
                        fullWidth={true}
                        value={row.example}
                        disabled={readonly}
                        onChange={(value) => onChange(["example"], value)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <DataInput
                        config={{
                            type: UIModelFieldType.text,
                            title: t("tables:calling_list.tabs.normalize_rules.fields.replace_result.title"),
                            field: "replace_result"
                        }}
                        fullWidth={true}
                        value={createValidateValue(result, true)}
                        disabled={true}
                        onChange={(value) => {
                        }}
                    />
                </Grid>
            </Grid>
        </CardContent>
        <span>
            <IconButton color={"secondary"} onClick={onDelete} disabled={readonly}>
                <Delete/>
            </IconButton>
        </span>
    </Card>;
}

const useStyles = makeStyles({
    details: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        marginTop: "1em"
    }
});

export default UploadListNormalizeRules;
