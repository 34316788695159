"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DialerCollections = void 0;
var DialerCollections;
(function (DialerCollections) {
    DialerCollections["calling_list"] = "calling_list";
    DialerCollections["uploaded_list"] = "uploaded_list";
    DialerCollections["uploaded_list_phone"] = "uploaded_list_phone";
    DialerCollections["work_calendar"] = "work_calendar";
    DialerCollections["strategy"] = "strategy";
    DialerCollections["selection"] = "selection";
    DialerCollections["campaign"] = "campaign";
    DialerCollections["timezone"] = "timezone";
    DialerCollections["trunk"] = "trunk";
    DialerCollections["call"] = "call";
    DialerCollections["setting"] = "setting";
    DialerCollections["call_webhook"] = "call_webhook";
})(DialerCollections = exports.DialerCollections || (exports.DialerCollections = {}));
