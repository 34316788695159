"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.interpolateString = exports.evalExpression = void 0;
const common_types_1 = require("@aatdev/common-types");
const evalRule = (lvalue, rvalue, operand, type) => {
    switch (operand) {
        case common_types_1.ExpressionOperandType.eq:
        case common_types_1.ExpressionOperandType.neq:
            if (type === "number") {
                return +lvalue === +rvalue;
            }
            return lvalue === rvalue;
        case common_types_1.ExpressionOperandType.empty:
            return lvalue === undefined || lvalue.toString().length === 0;
        case common_types_1.ExpressionOperandType.notEmpty:
            return lvalue !== undefined && lvalue.toString().length > 0;
        case common_types_1.ExpressionOperandType.startsWith:
            return lvalue !== undefined && rvalue !== undefined && lvalue.toString().startsWith(rvalue.toString());
        default: // number
            if (type !== "number") {
                return false;
            }
            const lv = +lvalue;
            const rv = +rvalue;
            if (isNaN(lv) || isNaN(rv)) {
                return false;
            }
            switch (operand) {
                case common_types_1.ExpressionOperandType.gt:
                    return lv > rv;
                case common_types_1.ExpressionOperandType.gte:
                    return lv >= rv;
                case common_types_1.ExpressionOperandType.lt:
                    return lv < rv;
                case common_types_1.ExpressionOperandType.lte:
                    return lv <= rv;
            }
    }
    return false;
};
const evalExpression = (expr, values) => {
    if (expr.type === "rule" && expr.field) {
        const vv = values[expr.field];
        const rvalue = (0, exports.interpolateString)(expr.value, values);
        if (vv !== undefined && rvalue !== undefined) {
            return evalRule(vv, expr.value, expr.operand, expr.value_type || "string");
        }
        return false;
    }
    if (expr.type === "set") {
        let res = expr.op === "and";
        for (const r of expr.inner) {
            if (expr.op === "and") {
                res = res && (0, exports.evalExpression)(r, values);
            }
            else {
                res = res || (0, exports.evalExpression)(r, values);
            }
        }
        return res;
    }
    return false;
};
exports.evalExpression = evalExpression;
const interpolateString = (tpl, args) => {
    if (typeof tpl === "string") {
        return tpl.replace(/\${([^{}]*)}/g, function (a, b) {
            const s = b.split('.');
            let r = args[b];
            if (s.length > 1) {
                r = args[s[0]][s[1]];
            }
            if (r instanceof Date) {
                return r.toISOString();
            }
            return typeof r === 'string' || typeof r === 'number' ? r : a.toString();
        });
    }
    return tpl;
};
exports.interpolateString = interpolateString;
