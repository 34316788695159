import {StompCampaignStatMsg, StompProjectStatMsg} from "@aatdev/dialer-types";
import {StatsActionType} from "../actions/StatsActionTypes";
import {GeneralAction} from "../StoreTypes";

export interface StatsAction<T> extends GeneralAction {
    payload: T
}

export type StatsState = {
    projectStat?: StompProjectStatMsg,
    campaignStat: Record<string, StompCampaignStatMsg>
}

const updateProjectStat = (state: StatsState, data: any) => {
    return {
        ...state,
        projectStat: data
    }
}

const updateCampaignStat = (state: StatsState, data: StompCampaignStatMsg) => {
    return {
        ...state,
        campaignStat: {
            ...state.campaignStat,
            [data.campaignId]: data
        }
    }
}

export const statsReducer = (state: StatsState = {campaignStat: {}}, action: StatsAction<any>) => {
    switch (action.type) {
        case StatsActionType.UPDATE_PROJECT_STAT:
            return updateProjectStat(state, action.payload);
        case StatsActionType.UPDATE_CAMPAIGN_STAT:
            return updateCampaignStat(state, action.payload);
    }
    return state;
}
