import {DialerCallingListModel} from "@aatdev/dialer-types";
import {FunctionComponent} from "react";
import {deleteValidatedData} from "../../../utils/ValidatedValueUtils";
import {DataFormProps} from "../../DataEditor/DataForm/DataFormTypes";
import UploadedListChain from "../Tables/Chains/UploadedListChain";

type Props = {} & DataFormProps;

const UploadedList: FunctionComponent<Props> = (props) => {
    const {data} = props.element;
    const callingList: DialerCallingListModel = deleteValidatedData(data);
    return <UploadedListChain
        callingList={callingList}
        prefixTableId={data._id.value}
        key={callingList._id}
    />
}

export default UploadedList;

