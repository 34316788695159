import {debounce} from "@material-ui/core";
import React, {CSSProperties} from 'react';
import EntityAutocomplete from '../EntityAutocomplete/EntityAutocomplete';
import {AutocompleteOption} from "../InfiniteAutocomplete/InfiniteAutocomplete";

type TypeFilterValue = {
    name: string;
    operator: string;
    type: string;
    value: string | AutocompleteOption;
    filterEditorProps?: any;
    dataSource?: any;
}

type SelectFilterProps = {
    filterValue?: TypeFilterValue;
    filterDelay?: number;
    onChange?: Function;
    readOnly?: boolean;
    disabled?: boolean;
    style?: CSSProperties;
    nativeScroll?: boolean;
    filterEditorProps?: any;
    rtl?: boolean;
    theme?: string;
    render?: Function;
}

type SelectFilterState = {
    value?: string | AutocompleteOption;
}

class EntityFilter extends React.Component<SelectFilterProps, SelectFilterState> {
    constructor(props: SelectFilterProps) {
        super(props);

        const {filterValue} = props;

        this.state = {
            value: filterValue ? filterValue.value || null : null,
        };

        this.onChange = this.onChange.bind(this);
        this.onValueChange = this.onValueChange.bind(this);

        if (props.filterDelay && props.filterDelay >= 1) {
            this.onValueChange = debounce(this.onValueChange, props.filterDelay);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: SelectFilterProps) {
        if (
            nextProps.filterValue &&
            nextProps.filterValue.value !== this.state.value
        ) {
            const value = nextProps.filterValue.value;
            this.setValue(value as AutocompleteOption);
        }
    }

    onChange(value: AutocompleteOption) {
        this.onValueChange(value);
        this.setValue(value);
    }

    setValue(value: AutocompleteOption) {
        this.setState({
            value,
        });
    }

    onValueChange(option: AutocompleteOption | null) {
        this.props.onChange &&
        this.props.onChange({
            ...this.props.filterValue,
            value: option,
        });
    }

    render() {
        let {readOnly, disabled, filterEditorProps, filterValue} = this.props;
        return this.props.render && this.props.render(
            <EntityAutocomplete
                value={filterValue?.value as AutocompleteOption}
                entityType={filterEditorProps.entityType}
                filterOp={filterValue?.operator}
                placeholder={filterEditorProps.placeholder}
                disabled={disabled || readOnly}
                onChange={option => {
                    this.onValueChange(option as AutocompleteOption);
                }}
            />
        );
    }
}

export default EntityFilter;
