import {Dialog, DialogContent, DialogTitle, LinearProgress} from "@material-ui/core";
import {FunctionComponent} from "react";

type Props = {
    open: boolean,
    title: string
}

const ProgressDialog: FunctionComponent<Props> = (props) => {
    const {open, title} = props;
    return <Dialog open={open}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent style={{minWidth: 500}}>
            <LinearProgress/>
        </DialogContent>
    </Dialog>
}

export default ProgressDialog;
