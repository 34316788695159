import {FunctionComponent} from "react";
import CallListTable from "../Tables/CallListTable";

type Props = {};

const CallReport: FunctionComponent<Props> = (props: Props) => {
    return <CallListTable
        tableId="call_report"
        params={{
            additinalFields: ["campaign_id", "calling_list_id"]
        }}
    />
}

export default CallReport;
