import {DBElement} from "@aatdev/common-types";

export const getKeyFromDate = (date: Date | null) => {
    if (date) {
        return `id-${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    }
    return "";
}

export const calendarDataToMap = (calendarData: DBElement[]) => {
    return calendarData.reduce((pv: any, cv) => {
        pv[cv._id] = cv;
        return pv;
    }, {})
}
