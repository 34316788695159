import {DBElementDep, DBElementDeps} from "@aatdev/common-types";
import {IconButton} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {OpenInNew} from "@material-ui/icons";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {TreeItem} from "@material-ui/lab";
import TreeView from "@material-ui/lab/TreeView";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {AppState} from "../../store/StoreTypes";

type Props = {
    deps: DBElementDeps
    onOpenElement: (collection: string, id: string) => void
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        labelText: {
            flexGrow: 1,
            alignSelf: "center"
        },
        labelRoot: {
            display: "flex",
            padding: "1px"
        }
    })
);

const DepsTree: FunctionComponent<Props> = (props: Props) => {
    const {deps, onOpenElement} = props;
    const styles = useStyles();
    const {t} = useTranslation();
    const models = useSelector((state: AppState) => state.db.loadedModels);

    const getCollectionLabel = (item: DBElementDep) => {
        if (item.count > item.items.length) {
            return `${t(item.title)} (${t('dialogs:deps.messages.shown', {
                size: item.items.length,
                total: item.count
            })})`;
        }
        return t(item.title);
    }

    return <TreeView
        defaultCollapseIcon={<ExpandMoreIcon/>}
        defaultExpandIcon={<ChevronRightIcon/>}
        expanded={deps.map(e => e.collection)}>
        {deps.map(collection => {
            const meta = models[collection.collection].meta;
            if (meta) {
                return <TreeItem nodeId={collection.collection} label={getCollectionLabel(collection)}
                                 key={collection.collection}>
                    {collection.items.map(value => {
                        return <TreeItem
                            key={value._id}
                            label={
                                <div className={styles.labelRoot}>
                                    <Typography variant="body2" className={styles.labelText}>
                                        {value.name}
                                    </Typography>
                                    {meta.menu_item && <Typography variant="caption" color="inherit">
                                        <IconButton
                                            color={"primary"}
                                            size={"small"}
                                            onClick={() => onOpenElement(collection.collection, value._id)}>
                                            <OpenInNew/>
                                        </IconButton>
                                    </Typography>}
                                </div>
                            }
                            nodeId={value._id}/>
                    })}
                </TreeItem>
            }
            return null;
        })}
    </TreeView>
}

export default DepsTree;
