import {UIModelFieldType, ValidatedValue} from "@aatdev/common-types";
import {Grid} from "@material-ui/core";
import range from 'lodash.range';
import {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {createFieldConfig} from "../../CommonDataTable/CommonDataTableUtils";
import DataInput from "../../DataEditor/DataForm/DataInput";

export type AttemptsValue = {
    count: ValidatedValue<number>,
    values: ValidatedValue<number>[]
}

type Props = {
    onChange: (value: AttemptsValue) => void,
    labels: {
        count: string,
        intervals: string,
        attempt: string
    },
    readonly: boolean,
    defaultInterval?: number
} & AttemptsValue;

const AttemptsEditor: FunctionComponent<Props> = (props: Props) => {
    const {labels, count, onChange, values = [], defaultInterval = 1, readonly} = props;
    const {t} = useTranslation();

    const handleChangeCount = (value: ValidatedValue<number>) => {
        onChange({
            count: value,
            values: values
        });
    }

    const handleChangeValue = (index: number) => (value: ValidatedValue<number>) => {
        const vv = {...values};
        vv[index] = value;
        onChange({
            count: count,
            values: vv
        });
    }

    return <Grid container spacing={0}>
        <Grid item xs={2} style={{margin: "auto 0"}}>
            <DataInput
                config={createFieldConfig(UIModelFieldType.int, t(labels.count), "count")}
                style={{width: "7em", margin: 4}}
                disabled={readonly}
                constrains={"greaterThan0"}
                value={count}
                default={1}
                onChange={handleChangeCount}
            />
        </Grid>
        <Grid item style={{margin: "12px"}}>
            {count?.value > 1 ? t(labels.intervals) : ""}
        </Grid>
        {range((count?.value || 1) - 1).map((i) => {
            return <Grid item key={i} style={{margin: "auto 0"}}>
                <DataInput
                    config={createFieldConfig(UIModelFieldType.int, t(labels.attempt, {
                        a1: (i + 1),
                        a2: (i + 2)
                    }), "values")}
                    default={defaultInterval}
                    disabled={readonly}
                    style={{width: "7em", margin: 4}}
                    constrains={"greaterThan0"}
                    value={values[i]}
                    onChange={handleChangeValue(i)}
                />
            </Grid>
        })}
    </Grid>;
}

export default AttemptsEditor;
