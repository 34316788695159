import {SnackbarKey} from "notistack";
import {Action} from "redux";

export type NotifierType = "default" | "error" | "success" | "warning" | "info" | undefined;

export enum NotiferActionType {
    ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR",
    CLOSE_SNACKBAR = "CLOSE_SNACKBAR",
    REMOVE_SNACKBAR = "REMOVE_SNACKBAR"
}

export type NotificationType = {
    message: string,
    dismissed?: boolean,
    key: SnackbarKey,
    options: {
        variant: "default" | "error" | "success" | "warning" | "info" | undefined
        action?: (key: SnackbarKey) => any
    } & Record<string, any>
}

export interface NotifierAction extends Action<NotiferActionType> {
    payload: {
        key?: SnackbarKey,
        notification?: NotificationType,
        dismissAll?: boolean
    }
}

export type NotifierStateProps = {
    notify: (message: string, type: NotifierType) => void
}
