import {Checkbox, InputAdornment, Popper} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import {PopperProps} from "@material-ui/core/Popper";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {AutocompleteRenderInputParams} from "@material-ui/lab/Autocomplete";
import React from "react";
import {useTranslation} from "react-i18next";
import {getDBElementName} from "../../utils/ModelUtils";
import HelpButton from "../HelpWrapper/HelpButton";
import InfiniteWrapper from "./InfiniteWrapper";

export type AutocompleteOption = {
    _id: string
    name: string
} | null;

export type InfiniteAutocompleteProps = {
    placeholder: string
    hasNextPage: boolean
    isNextPageLoading: boolean
    items: AutocompleteOption[]
    loadNextPage: (startIndex: number, stopIndex: number) => void
    onSelect: (value: AutocompleteOption | AutocompleteOption[] | null) => void
    onOpen: (state: boolean) => void
    inputValue: string
    value: AutocompleteOption | null
    total: number
    label?: string
    error?: boolean
    helperText?: string
    onInput?: (text: string) => void
    size?: 'small' | 'medium'
    variant?: 'standard' | 'outlined' | 'filled'
    renderInput?: (params: AutocompleteRenderInputParams) => any
    disableFilterOptions?: boolean
    disabled?: boolean
    multiple?: boolean
    helpId?: string[]
    showArchived?: boolean
    onShowArchivedChanged?: () => void
    noOptionsText?: string
}

const useStyles = makeStyles({
    listbox: {
        "& ul": {
            padding: 0,
            margin: 0
        }
    }
});

const AutoCompletePopper = (props: PopperProps) => {
    return (<Popper
        {...props}
        placement={"bottom-start"}
        style={{width: "auto"}}
    />);
}

export default function InfiniteAutocomplete(props: InfiniteAutocompleteProps) {
    const {helpId = [], showArchived, onShowArchivedChanged, noOptionsText} = props;
    const classes = useStyles();
    const {t} = useTranslation();

    const renderInput = (params: AutocompleteRenderInputParams) => {
        return <TextField
            {...params}
            label={props.label}
            error={props.error}
            title={(params.inputProps as any).value}
            size={props.size || 'small'}
            variant={props.variant || 'outlined'}
            fullWidth
            placeholder={props.placeholder}
            disabled={props.disabled}
            InputProps={{
                ...params.InputProps,
                endAdornment: (
                    <>
                        <InputAdornment position="end">
                            {props.isNextPageLoading ? <CircularProgress color='inherit' size={20}/> : null}
                            {params.InputProps.endAdornment}
                            {helpId?.length > 0 && <HelpButton type={"popup"} helpId={helpId}/>}
                        </InputAdornment>
                    </>
                ),
            }}
        />;
    }

    const handleOnInput = (text: string) => {
        if (props.onInput) {
            props.onInput(text);
        }
    }

    return (
        <span style={{display: 'flex'}}>
            <Autocomplete
                style={{width: '100%'}}
                disableListWrap
                selectOnFocus={false}
                classes={classes}
                PopperComponent={AutoCompletePopper}
                ListboxProps={{
                    onSelect: props.onSelect,
                    onInput: props.onInput,
                    items: props.items,
                    total: props.total,
                    loadNextPage: props.loadNextPage,
                    hasNextPage: props.hasNextPage,
                    isNextPageLoading: props.isNextPageLoading
                }}
                noOptionsText={noOptionsText}
                ListboxComponent={InfiniteWrapper as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
                options={props.items}
                getOptionSelected={(option, value) => {
                    return option?._id === value?._id;
                }}
                getOptionLabel={option => getDBElementName(option)}
                onInput={(event: any) => {
                    handleOnInput(event.target.value);
                }}
                onClose={() => {
                    handleOnInput('');
                    props.onOpen(false);
                }}
                onChange={(_, option) => props.onSelect(option)}
                onOpen={() => props.onOpen(true)}
                filterOptions={props.disableFilterOptions ? (options, state) => {
                    return options;
                } : undefined}
                renderInput={renderInput}
                value={props.value || null}
                disabled={!!props.disabled}
                multiple={!!props.multiple}
            />
            {onShowArchivedChanged && !props.disabled &&
            <Checkbox size={"small"} checked={showArchived} onChange={onShowArchivedChanged}
                      title={t("common:buttons.archive")}/>}
        </span>
    );
}
