"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CallCausesPredefined = exports.CallState = void 0;
var CallState;
(function (CallState) {
    CallState["HANGUP"] = "HANGUP";
    CallState["RINGING"] = "RINGING";
    CallState["EARLY"] = "EARLY";
    CallState["ACTIVE"] = "ACTIVE";
    CallState["ERROR"] = "ERROR";
    CallState["NONE"] = "";
})(CallState = exports.CallState || (exports.CallState = {}));
var CallCausesPredefined;
(function (CallCausesPredefined) {
    CallCausesPredefined["UNSPECIFIED"] = "UNSPECIFIED";
    CallCausesPredefined["UNALLOCATED_NUMBER"] = "UNALLOCATED_NUMBER";
    CallCausesPredefined["NO_ROUTE_TRANSIT_NET"] = "NO_ROUTE_TRANSIT_NET";
    CallCausesPredefined["NO_ROUTE_DESTINATION"] = "NO_ROUTE_DESTINATION";
    CallCausesPredefined["CHANNEL_UNACCEPTABLE"] = "CHANNEL_UNACCEPTABLE";
    CallCausesPredefined["CALL_AWARDED_DELIVERED"] = "CALL_AWARDED_DELIVERED";
    CallCausesPredefined["NORMAL_CLEARING"] = "NORMAL_CLEARING";
    CallCausesPredefined["USER_BUSY"] = "USER_BUSY";
    CallCausesPredefined["NO_USER_RESPONSE"] = "NO_USER_RESPONSE";
    CallCausesPredefined["NO_ANSWER"] = "NO_ANSWER";
    CallCausesPredefined["USER_NOT_REGISTERED"] = "USER_NOT_REGISTERED";
    CallCausesPredefined["SUBSCRIBER_ABSENT"] = "SUBSCRIBER_ABSENT";
    CallCausesPredefined["CALL_REJECTED"] = "CALL_REJECTED";
    CallCausesPredefined["NUMBER_CHANGED"] = "NUMBER_CHANGED";
    CallCausesPredefined["INVALID_NUMBER_FORMAT"] = "INVALID_NUMBER_FORMAT";
    CallCausesPredefined["NETWORK_OUT_OF_ORDER"] = "NETWORK_OUT_OF_ORDER";
    CallCausesPredefined["NORMAL_CIRCUIT_CONGESTION"] = "NORMAL_CIRCUIT_CONGESTION";
    CallCausesPredefined["INCOMPATIBLE_DESTINATION"] = "INCOMPATIBLE_DESTINATION";
    CallCausesPredefined["DESTINATION_OUT_OF_ORDER"] = "DESTINATION_OUT_OF_ORDER";
    CallCausesPredefined["FACILITY_REJECTED"] = "FACILITY_REJECTED";
    CallCausesPredefined["RESPONSE_TO_STATUS_ENQUIRY"] = "RESPONSE_TO_STATUS_ENQUIRY";
    CallCausesPredefined["NORMAL_TEMPORARY_FAILURE"] = "NORMAL_TEMPORARY_FAILURE";
    CallCausesPredefined["SWITCH_CONGESTION"] = "SWITCH_CONGESTION";
    CallCausesPredefined["REQUESTED_CHAN_UNAVAIL"] = "REQUESTED_CHAN_UNAVAIL";
    CallCausesPredefined["SERVICE_UNAVAILABLE"] = "SERVICE_UNAVAILABLE";
    CallCausesPredefined["CHAN_NOT_IMPLEMENTED"] = "CHAN_NOT_IMPLEMENTED";
    CallCausesPredefined["SERVICE_NOT_IMPLEMENTED"] = "SERVICE_NOT_IMPLEMENTED";
    CallCausesPredefined["UNKNOWN"] = "UNKNOWN";
    CallCausesPredefined["ERROR"] = "ERROR";
    CallCausesPredefined["NONE"] = "";
    /*
    BEARERCAPABILITY_NOTIMPL = "BEARERCAPABILITY_NOTIMPL",
    ACCESS_INFO_DISCARDED = "ACCESS_INFO_DISCARDED",
    NORMAL_UNSPECIFIED = "NORMAL_UNSPECIFIED",
    EXCHANGE_ROUTING_ERROR = "EXCHANGE_ROUTING_ERROR",
    REDIRECTION_TO_NEW_DESTINATION = "REDIRECTION_TO_NEW_DESTINATION",
    FACILITY_NOT_IMPLEMENTED = "FACILITY_NOT_IMPLEMENTED",
    INVALID_CALL_REFERENCE = "INVALID_CALL_REFERENCE",
    INVALID_MSG_UNSPECIFIED = "INVALID_MSG_UNSPECIFIED",
    MANDATORY_IE_MISSING = "MANDATORY_IE_MISSING",
    MESSAGE_TYPE_NONEXIST = "MESSAGE_TYPE_NONEXIST",
    PRE_EMPTED = "PRE_EMPTED",
    FACILITY_NOT_SUBSCRIBED = "FACILITY_NOT_SUBSCRIBED",
    OUTGOING_CALL_BARRED = "OUTGOING_CALL_BARRED",
    INCOMING_CALL_BARRED = "INCOMING_CALL_BARRED",
    BEARERCAPABILITY_NOTAUTH = "BEARERCAPABILITY_NOTAUTH",
    BEARERCAPABILITY_NOTAVAIL = "BEARERCAPABILITY_NOTAVAIL",
    IE_NONEXIST = "IE_NONEXIST",
    INVALID_IE_CONTENTS = "INVALID_IE_CONTENTS",
    WRONG_CALL_STATE = "WRONG_CALL_STATE",
    RECOVERY_ON_TIMER_EXPIRE = "RECOVERY_ON_TIMER_EXPIRE",
    MANDATORY_IE_LENGTH_ERROR = "MANDATORY_IE_LENGTH_ERROR",
    PROTOCOL_ERROR = "PROTOCOL_ERROR",
    WRONG_MESSAGE = "WRONG_MESSAGE",
    ORIGINATOR_CANCEL = "ORIGINATOR_CANCEL",
    CRASH = "CRASH",
    SYSTEM_SHUTDOWN = "SYSTEM_SHUTDOWN",
    LOSE_RACE = "LOSE_RACE",
    MANAGER_REQUEST = "MANAGER_REQUEST",
    BLIND_TRANSFER = "BLIND_TRANSFER",
    ATTENDED_TRANSFER = "ATTENDED_TRANSFER",
    ALLOTTED_TIMEOUT = "ALLOTTED_TIMEOUT",
    USER_CHALLENGE = "USER_CHALLENGE",
    MEDIA_TIMEOUT = "MEDIA_TIMEOUT",
    PICKED_OFF = "PICKED_OFF",
    INTERWORKING = "INTERWORKING",
    PROGRESS_TIMEOUT = "PROGRESS_TIMEOUT",
    GATEWAY_DOWN = "GATEWAY_DOWN",
    */
})(CallCausesPredefined = exports.CallCausesPredefined || (exports.CallCausesPredefined = {}));
