import {LoggedUser} from "@aatdev/common-types";
import {Split} from '@geoffcox/react-splitter';
import {createStyles, CssBaseline, makeStyles, Theme} from "@material-ui/core";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Container from "./components/Container/Container";
import YesNoDialog from "./components/Dialogs/YesNoDialog";
import LoadIndicator from "./components/LoadIndicator/LoadIndicator";
import LoginForm from "./components/LoginForm/LoginForm";
import MainDrawer from "./components/MainDrawer/MainDrawer";
import MainToolbar from "./components/MainToolbar/MainToolbar";
import Notifier from "./components/Notifier/Notifier";
import {backend} from "./data/Backend";
import LocalStoreService from './services/LocalStoreService';
import {blockNavigateAction} from "./store/actions/DataActions";
import {changeLoggedStateAction} from "./store/actions/GeneralActions";
import {AppState} from "./store/StoreTypes";
import {getToolbarTopMargin} from "./utils/AppTheme";
import {DRAWER_WIDTH} from "./utils/Constants";
import {useMenuItems} from "./utils/UseMenuItems";

type Props = {}

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100vh',
        },
        content: {
            width: '100%',
            boxSizing: 'border-box',
            position: 'relative',
            flexGrow: 1,
            marginTop: getToolbarTopMargin(),
        },
        contentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            })
        },
    })
});

const getDrawerWidth = () => LocalStoreService.getAsNumber('drawer-width', DRAWER_WIDTH);

export const App: FunctionComponent<Props> = (props: Props) => {
    const styles = useStyles();
    const blockedNavigate = useSelector((state: AppState) => state.db.blockedNavigate);
    const loadingState = useSelector((state: AppState) => state.general.loading);
    const [drawerOpen, setDrawerOpen] = useState(LocalStoreService.getAsBoolean('drawer-open', true));
    const [drawerWidth, setDrawerWidth] = useState(getDrawerWidth());
    const menu = useMenuItems();
    const dispatch = useDispatch();
    const user = useSelector((state: AppState) => state.general.user);
    const {t} = useTranslation();
    const [profile, setProfile] = useState<any>();

    useEffect(() => {
        const prevTitle = document.title
        document.title = t('common:head.title');
        return () => {
            document.title = prevTitle
        }
    });

    useEffect(() => {
        if (!profile) {
            backend.profile().then(res => {
                const user = res?.data as LoggedUser;
                if (user) {
                    // FIXME: at the moment only ru lang is supported
                    LocalStoreService.set("i18nextLng", 'ru');
                    setProfile(user);
                    dispatch(changeLoggedStateAction(user));
                }
            });
        } else {
            if (!user) {
                setProfile(undefined);
            }
        }
    }, [dispatch, user]);


    const handleLogout = () => {
        backend.logout().finally(() => {
            setProfile(undefined);
            dispatch(changeLoggedStateAction(undefined));
        });
    }

    const handleDrawerOpen = (open: boolean) => {
        setDrawerOpen(open);
        LocalStoreService.set("drawer-open", open);
    }

    if (profile) {
        return <div className={styles.root}>
            <LoadIndicator open={!!loadingState?.isActive}/>
            <YesNoDialog
                title={t('common:blockedNavigate.title')}
                content={t('common:blockedNavigate.content')}
                open={!!blockedNavigate}
                onClose={yes => {
                    if (yes && blockedNavigate) {
                        blockedNavigate.goAction();
                    }
                    dispatch(blockNavigateAction(undefined));
                }}
            />
            <Notifier/>
            <CssBaseline/>
            <MainToolbar
                onOpenDrawer={() => handleDrawerOpen(true)}
                onLogout={handleLogout}
            />
            <div className={styles.content}>
                {drawerOpen &&
                <Split
                    minPrimarySize={"100px"}
                    minSecondarySize={"100px"}
                    initialPrimarySize={`${getDrawerWidth()}px`}
                    splitterSize={'3px'}
                    onMeasuredSizesChanged={sizes => {
                        LocalStoreService.set("drawer-width", sizes.primary);
                        setDrawerWidth(sizes.primary);
                    }}>
                    <MainDrawer
                        open={drawerOpen} onClose={() => handleDrawerOpen(false)} menu={menu}
                        width={drawerWidth}
                    />
                    <Container menu={menu}/>
                </Split>}
                {!drawerOpen && <Container menu={menu}/>}
            </div>
        </div>
    } else {
        return <LoginForm onLogin={(user) => {
            dispatch(changeLoggedStateAction(user));
        }}/>;
    }
};


export default App;
