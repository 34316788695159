import {DBElementPermissions, UIModelFieldSelectItem, UIModelFieldType} from "@aatdev/common-types";
import {DialerStructureItemSortDirection, DialerStructureItemType} from "@aatdev/dialer-types";
import {Button, Grid, IconButton, Paper, Table, TableCell, TableHead, TableRow} from "@material-ui/core";
import {DeleteOutline, DeleteRounded} from "@material-ui/icons";
import {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {v4} from "uuid";
import {deleteInnerField, pushField, updateInnerField} from "../../../utils/ModelUtils";
import {createValidateValue} from "../../../utils/ValidatedValueUtils";
import {DataFormProps} from "../../DataEditor/DataForm/DataFormTypes";
import DataInput from "../../DataEditor/DataForm/DataInput";
import TableHeaderCell from "../../Utils/TableHeaderCell";
import {StructureItemValidated} from "../Data/Models/CallingListValidated";

type StrategyPhoneRulesProps = {} & DataFormProps;

const CallingListStructure: FunctionComponent<StrategyPhoneRulesProps> = (props: StrategyPhoneRulesProps) => {
    const {onChange, element, fieldPrefix, schema} = props;
    const {data, deps, permissions} = element;
    const tabData: StructureItemValidated[] = data[fieldPrefix] || [];
    const disabled = schema.disabled_if_deps && deps.length > 0;
    const {t} = useTranslation();

    const getDefaultItem = (): StructureItemValidated => ({
        id: createValidateValue(v4().toString(), true),
        type: createValidateValue(DialerStructureItemType.string, true),
        name: createValidateValue(""),
        unique: createValidateValue(false, true),
        sortDirection: createValidateValue("none", true),
        sortIndex: createValidateValue(0, true),
        sendInInvite: createValidateValue(false, true)
    });

    const handleAdd = () => {
        onChange((data: StructureItemValidated[]) => pushField(data || [], [], getDefaultItem()));
    }

    const handleDelete = (index: number) => {
        onChange((data: StructureItemValidated[]) => deleteInnerField(data, [index]));
    }

    return <Grid container spacing={2}>
        <Grid item xs={12} style={{textAlign: "right"}}>
            <Button variant={"outlined"} color={"primary"} onClick={handleAdd} style={{marginLeft: "auto"}}
                    disabled={disabled || !permissions.edit}>
                {t("common:buttons.add")}
            </Button>
        </Grid>
        <Paper style={{width: "100%"}}>
            <Table size={"small"}>
                <TableHead>
                    <TableRow>
                        <TableHeaderCell>{t("tables:calling_list.tabs.structure.fields.name.title")}</TableHeaderCell>
                        <TableHeaderCell
                            width={250}>{t("tables:calling_list.tabs.structure.fields.type.title")}</TableHeaderCell>
                        <TableHeaderCell
                            width={270}>{t("tables:calling_list.tabs.structure.fields.sortDirection.title")}</TableHeaderCell>
                        <TableHeaderCell
                            width={"2%"}>{t("tables:calling_list.tabs.structure.fields.sortIndex.title")}
                        </TableHeaderCell>
                        <TableHeaderCell
                            width={"2%"}>{t("tables:calling_list.tabs.structure.fields.unique.title")}
                        </TableHeaderCell>
                        <TableHeaderCell
                            width={"2%"}>{t("tables:calling_list.tabs.structure.fields.sendInInvite.title")}
                        </TableHeaderCell>
                        <TableHeaderCell width={"2%"}><DeleteOutline/></TableHeaderCell>
                    </TableRow>
                </TableHead>
                {tabData.map((e, index) => <ItemEditor
                    key={index}
                    item={e}
                    allItems={tabData}
                    permissions={permissions}
                    disabled={disabled}
                    onDelete={() => handleDelete(index)}
                    onChange={(item) => onChange((data: StructureItemValidated[]) => {
                        return updateInnerField(data, [index], item);
                    })}
                />)}
            </Table>
        </Paper>
    </Grid>;
}

type ItemEditorProps = {
    item: StructureItemValidated,
    allItems: StructureItemValidated[],
    permissions: DBElementPermissions,
    onChange: (type: StructureItemValidated) => void,
    onDelete: () => void,
    disabled?: boolean,
}

const ItemEditor: FunctionComponent<ItemEditorProps> = (props: ItemEditorProps) => {
    const {item, onChange, onDelete, disabled, permissions, allItems} = props;
    const {t} = useTranslation();

    const getTypeOptions = () => {
        return Object.keys(DialerStructureItemType).reduce((pv: Record<string, UIModelFieldSelectItem>, cv) => {
            pv[cv] = {
                label: t(`tables:calling_list.tabs.structure.fields.type.values.${cv}`),
                // allow only one time usage
                disabled: allItems.findIndex(e => e.type.value === cv) > -1 && (cv === DialerStructureItemType.timezone || cv === DialerStructureItemType.contactId)
            };
            return pv;
        }, {});
    }

    return <TableRow>
        <TableCell>
            <DataInput
                config={{
                    type: UIModelFieldType.text,
                    title: "",
                    field: "name"
                }}
                fullWidth={true}
                constrains={"notEmpty"}
                value={item.name}
                disabled={disabled}
                onChange={(value) => onChange({...item, name: value})}
            />
        </TableCell>
        <TableCell>
            <DataInput config={{
                type: UIModelFieldType.select,
                title: "",
                field: "type",
                items: getTypeOptions()
            }} constrains={"notEmpty"}
                       value={item.type}
                       disabled={disabled || !permissions.edit}
                       fullWidth={true}
                       onChange={(value) => onChange({...item, type: value})}/>
        </TableCell>
        <TableCell>
            <DataInput
                config={{
                    type: UIModelFieldType.select,
                    title: "",
                    field: "sortDirection",
                    items: Object.values(DialerStructureItemSortDirection).reduce((pv: Record<string, UIModelFieldSelectItem>, cv) => {
                        pv[cv] = {
                            label: t(`tables:calling_list.tabs.structure.fields.sortDirection.values.${cv}`)
                        };
                        return pv;
                    }, {})
                }}
                fullWidth={true}
                value={item.sortDirection}
                disabled={!permissions.edit}
                onChange={(value) => onChange({...item, sortDirection: value})}
            />
        </TableCell>
        <TableCell>
            <DataInput
                config={{
                    type: UIModelFieldType.int,
                    title: "",
                    field: "sortIndex"
                }}
                fullWidth={true}
                value={item.sortIndex}
                disabled={!permissions.edit || !item.sortDirection}
                onChange={(value) => onChange({...item, sortIndex: value})}
            />
        </TableCell>
        <TableCell>
            <DataInput
                config={{
                    type: UIModelFieldType.boolean,
                    title: "",
                    field: "unique"
                }}
                fullWidth={true}
                value={item.unique}
                default={false}
                disabled={!permissions.edit}
                onChange={(value) => onChange({...item, unique: value})}
            />
        </TableCell>
        <TableCell>
            <DataInput
                config={{
                    type: UIModelFieldType.boolean,
                    title: "",
                    field: "sendInInvite"
                }}
                fullWidth={true}
                default={false}
                value={item.sendInInvite}
                disabled={!permissions.edit}
                onChange={(value) => onChange({...item, sendInInvite: value})}
            />
        </TableCell>
        <TableCell>
            <IconButton onClick={onDelete} size={"small"} disabled={disabled || !permissions.delete}>
                <DeleteRounded/>
            </IconButton>
        </TableCell>
    </TableRow>;
}

export default CallingListStructure;
