import {ElementWithDeps} from "@aatdev/common-types";
import {getTimezoneOffset} from "@aatdev/common-utils";
import {deleteValidatedData} from "../utils/ValidatedValueUtils";
import {backend} from "./Backend";

export const updateDatabase = async (collection: string, data: any, removeValidateData = true): Promise<ElementWithDeps<any>> => {
    const updated = removeValidateData ? deleteValidatedData(data) : data;
    updated.timezone_offset = getTimezoneOffset();
    return (await backend.update(collection, updated)).data;
}
