"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAnswerCause = exports.isNoAnswerCause = exports.isBusyCause = exports.replaceAccordingToRules = void 0;
var dialer_types_1 = require("@aatdev/dialer-types");
var replaceAccordingToRules = function (rules, text) {
    var _loop_1 = function (item) {
        var match = new RegExp('^' + item.rule + '$', 'g').exec(text);
        var result = item.replace;
        if (match) {
            match.forEach(function (value, index) {
                result = result.replace(new RegExp("\\{".concat(index, "\\}"), 'g'), value);
            });
            return { value: result };
        }
    };
    for (var _i = 0, rules_1 = rules; _i < rules_1.length; _i++) {
        var item = rules_1[_i];
        var state_1 = _loop_1(item);
        if (typeof state_1 === "object")
            return state_1.value;
    }
    return text;
};
exports.replaceAccordingToRules = replaceAccordingToRules;
var isBusyCause = function (name) { return name == dialer_types_1.CallCausesPredefined.USER_BUSY; };
exports.isBusyCause = isBusyCause;
var isNoAnswerCause = function (name) { return name == dialer_types_1.CallCausesPredefined.NO_ANSWER; };
exports.isNoAnswerCause = isNoAnswerCause;
var isAnswerCause = function (name) { return name == dialer_types_1.CallCausesPredefined.NORMAL_CLEARING; };
exports.isAnswerCause = isAnswerCause;
