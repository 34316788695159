import {ValidatedValue} from "@aatdev/common-types";
import _ from "lodash";
import {MAIN_TAB_ID} from "./Constants";

/**
 * Convert any object that saved in the DB into validated representation
 * @param input - {
 *     key: 1,
 *     inner: {
 *         key: "aaa"
 *     },
 *     arr: [2]
 * }
 * @return - {
 *     key: {
 *         valid: true,
 *         value: 1
 *     },
 *     inner: {
 *         key2: {
 *             valid: true,
 *             value: "aaa"
 *         }
 *     },
 *     arr: [{
 *         valid: true,
 *         value :2
 *     }]
 * }
 */
export const addValidatedData = (input: any) => {
    let output: any = {};
    if (Array.isArray(input)) {
        output = [];
        input.forEach((v: any) => {
            output.push(addValidatedData(v));
        });
    } else {
        if (typeof input === "object" && !(input instanceof Date) && input !== null) {
            for (const key of Object.keys(input)) {
                // don't add validation data to the relation fields
                if (typeof input[key] === "object" && !key.endsWith('_id')) {
                    output[key] = addValidatedData(input[key]);
                } else {
                    output[key] = {
                        value: input[key],
                        ___valid___: true
                    } as ValidatedValue<any>;
                }
            }
        } else {
            return {
                value: input,
                ___valid___: true
            } as ValidatedValue<any>;
        }
    }
    return output;
}

export const deleteValidatedData = (input: any) => {
    if (input === undefined) return input;
    let result: any = {};
    if (Array.isArray(input)) {
        result = [];
        input.forEach((v: any) => {
            result.push(deleteValidatedData(v));
        });
    } else {
        if (input instanceof Date) {
            return input;
        } else if (typeof input === "object") {
            if (input.___valid___ !== undefined) {
                return input.value;
            } else {
                for (const key of Object.keys(input)) {
                    result[key] = deleteValidatedData(input[key]);
                }
            }
        } else {
            return input;
        }
    }
    return result;
}

export const isMainTab = (tabId: string) => tabId === MAIN_TAB_ID;

export const isDataValid = (data: any, tabId: string = ""): boolean => {
    return Object.keys(data).reduce((pv: boolean, cv) => {
        if (typeof data[cv] === "object") {
            if (data[cv].___valid___ !== undefined) {
                return pv && data[cv].___valid___ !== false;
            } else if (!isMainTab(tabId)) {
                return pv && isDataValid(data[cv], tabId);
            }
        } else if (Array.isArray(data[cv])) {
            return data[cv].reduce((apv: boolean, cpv: any) => {
                return apv && isDataValid(cpv, tabId);
            }, true);
        }
        return pv;
    }, true);
}

export const getDataHash = (input: any): string => {

    const copy = _.cloneDeepWith(input, (v) => {
        if (v instanceof Date) {
            return v.toISOString();
        }
    });

    const clear = (data: any) => {
        if (Array.isArray(data)) {
            data.forEach(e => e);
        }
        if (typeof data === "object") {
            Object.keys(data).forEach(key => {
                if (typeof data[key] === "object" && data[key]) {
                    if ((Array.isArray(data[key]) && key === "messages") || Object.keys(data[key]).length === 0) {
                        delete data[key];
                    } else {
                        clear(data[key]);
                    }
                }
            });
        }
    }

    clear(copy);

    return JSON.stringify(copy, (key: string, value: any) => {
        if (typeof value === "object") {
            if (value?.___valid___ !== undefined && value?.value === undefined) {
                return undefined;
            }
            return value;
        } else {
            if (key === "__valid__" || key === "updated") {
                return true;
            }
            return value;
        }
    });
}

export const createValidateValue = <T>(value: T, valid = false): ValidatedValue<T> => {
    return {
        value: value,
        ___valid___: valid
    }
}
