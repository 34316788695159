import {DBElement} from "@aatdev/common-types";
import '@aatdev/reactdatagrid/index.css';
import {Breadcrumbs, Chip} from "@material-ui/core";
import {emphasize, Theme, withStyles} from '@material-ui/core/styles'
import React, {FunctionComponent, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {clearTableState, updateTableChainAction} from "../../../store/actions/DataActions";
import {AppState} from "../../../store/StoreTypes";
import {PreconfiguredDataTableChainProps} from "./PreconfiguredDataTableTypes";

type Props = {} & PreconfiguredDataTableChainProps;

const StyledBreadcrumb = withStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        height: theme.spacing(3),
        color: theme.palette.primary.contrastText,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.light,
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(theme.palette.primary.light, 0.12),
        },
    },
}))(Chip) as typeof Chip;

const PreconfiguredDataTableChain: FunctionComponent<Props> = (fProps: Props) => {
    const dispatch = useDispatch();
    const state = useSelector((state: AppState) => state.db.tableChain[fProps.menuTableId]) || {
        path: [fProps]
    };

    const setPath = (path: PreconfiguredDataTableChainProps[], closed: PreconfiguredDataTableChainProps[] = []) => {
        dispatch(updateTableChainAction(fProps.menuTableId, "path", path));
        for (const c of closed) {
            dispatch(clearTableState(`${c.menuTableId}.${c.props.tableId}`));
        }
    }

    const getBreadcrumbs = (path: PreconfiguredDataTableChainProps[]) => {
        return <Breadcrumbs>
            {path.map((e, index) => <StyledBreadcrumb key={e.title} component="button" label={e.title} onClick={() => {
                const newPath = [...path.slice(0, index + 1)];
                setPath(newPath, path.slice(index + 1));
                setCurrent(getElementFromProps(path[index], newPath));
            }}/>)}
        </Breadcrumbs>;
    }

    const getElementFromProps = (pProps: PreconfiguredDataTableChainProps, path: any[]) => {
        const {component: Component, props, onNext, menuTableId} = pProps;
        return <Component {...props}
                          tableId={`${menuTableId}.${props.tableId}`}
                          breadcrumbs={getBreadcrumbs(path)}
                          onSelect={(element: DBElement) => {
                              if (onNext) {
                                  const params = onNext(element);
                                  const newPath = [...path, params];
                                  setPath([...path, params]);
                                  setCurrent(getElementFromProps(params, newPath));
                              }
                          }}/>
    }

    const [current, setCurrent] = useState<any>(getElementFromProps(state.path[state.path.length - 1], state.path));
    return <div>
        {current}
    </div>;
};

export default PreconfiguredDataTableChain;
