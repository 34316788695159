import {getTimezoneOffset} from "@aatdev/common-utils";
import _ from "lodash";
import moment from "moment";
import urlParser from "url-parse";

const defaultDateRegexp = () => /^\d{4}-\d{2}-\d{2}(\s\d{2}(:\d{2}){0,2})$/
const isoDateRegexp = () => /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/

export const convertTimeZone = (dt: Date, utcOffset: number, localOffset?: number): Date => {
    const date = new Date(dt.getTime());
    if (localOffset === undefined) localOffset = date.getTimezoneOffset();
    const tzMinToMs = (minutes: number) => {
        return minutes * 60 * 1000;
    }
    date.setTime(date.getTime() - tzMinToMs(localOffset) + tzMinToMs(utcOffset));
    return date;
}

export const parseDate = (value: any, regexp: RegExp = defaultDateRegexp(), utcOffset?: number) => {
    if (value && typeof value === 'string') {
        value = value.trim();
        const match = value.match(regexp)
        if (match) {
            /* if value === YYYY-MM-DD */
            if (value.length === 10) {
                value = value + ' 00:00:00';
            }
            let valueDate = new Date(value);
            if (!Number.isNaN(valueDate.getTime())) {
                if (utcOffset !== undefined) {
                    valueDate = convertTimeZone(valueDate, utcOffset);
                }
                return valueDate;
            }
        } else if (value.match(isoDateRegexp())) {
            /* iso date format which appeared to be a string */
            return new Date(value);
        }
    }
    return value
}

export const getTimestamp = () => new Date().getTime() / 1000;

export const downloadFile = (data: any, filename: string) => {
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); //or any other extension
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
        document.body.removeChild(link);
    }, 100)
}

/**
 * Add timezone to the UTC date, as result it will be an UTC date + timezone shift
 * @param date
 */
export const toTimezonedDate = (date: Date) => {
    return moment.utc(date).add(-getTimezoneOffset() * 60, "seconds");
}

export const getURLFromEnv = (name: string): string => {
    let value = process.env[name];
    if (!value) return "";

    const url = urlParser(value);
    if (url.hostname === "window.location.origin") {
        const origin = urlParser(window.location.origin);
        let {protocol, pathname} = url;
        let {hostname, port} = origin;
        if (pathname === '/') pathname = '';

        if (origin.protocol === "https:") {
            if (protocol === 'http:') protocol = "https:";
            else if (protocol === 'ws:') protocol = 'wss:';
        }
        return `${protocol}//${hostname}:${port}${pathname}`;
    }
    return value;
}

export const calcPercent = (value: number, total: number) => {
    if (!value || !total) {
        return '0%';
    }
    return _.round((value / total) * 100, 1) + '%';
}

export const filterDict = <T extends any>(data: Record<string, T>, isMatch: (item: T) => boolean): Record<string, T> => {
    return Object.keys(data).reduce((pv: any, cv) => {
        if (isMatch(data[cv])) {
            pv[cv] = data[cv];
        }
        return pv;
    }, {});
}
