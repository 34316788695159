import React, {FunctionComponent} from "react";
import {makeStyles, Paper} from "@material-ui/core";
import {getToolbarTopMargin} from "../../../utils/AppTheme";
import {withUIModel, WithUIModelProps} from "../../../utils/WithUIModel";
import {useEditableElement} from "../../../utils/UseEditableElement";
import {EditingElement, TableSubMenuItem} from "../../../store/actions/DataActionTypes";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router";
import {
    closeFormEditorThunk,
    saveElementThunk,
    updatePartEditingElementAction
} from "../../../store/actions/DataActions";
import {useBlockNavigate} from "../../../utils/UseBlockNavigate";
import {useCurrentMenuItem} from "../../../utils/UseCurrentMenuItem";
import DataFormTabs from "./DataFormTabs";

type Props = WithUIModelProps;

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        minHeight: `calc(100vh - ${getToolbarTopMargin(2)}px)`,
        width: "100%",
        minWidth: 600,
        padding: "1em",
        margin: "auto"
    },
    buttons: {
        display: "flex",
        width: "100%",
        "& button": {
            marginLeft: "1em"
        }
    },
});


const DataMenuFormTabs: FunctionComponent<Props> = (props: Props) => {
    const {uiModel} = props;
    const styles = useStyles();
    const menu = useCurrentMenuItem() as TableSubMenuItem;
    const element: EditingElement = useEditableElement(menu.elementId);
    const dispatch = useDispatch();
    const blockNavigate = useBlockNavigate();
    const navigate = useNavigate();

    if (!element) {
        return <span/>;
    }

    const handleDataChange = (element: EditingElement, values: Record<string, any>) => {
        dispatch(updatePartEditingElementAction(element.id, values));
    };

    const handleClose = (element: EditingElement) => {
        dispatch(closeFormEditorThunk(element.id, blockNavigate));
    }

    const handleSave = (element: EditingElement) => {
        dispatch(saveElementThunk(element, navigate));
    }

    return (
        <Paper className={styles.root}>
            <DataFormTabs
                uiModel={uiModel}
                element={element}
                onClose={handleClose}
                onSave={handleSave}
                onChange={handleDataChange}
            />
        </Paper>
    );
}

export default withUIModel(DataMenuFormTabs);
