import {DialerCampaignModel, DialerCollections, DialerUploadedListPhoneModel} from "@aatdev/dialer-types";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {FunctionComponent} from "react";
import {getToolbarTopMargin} from "../../../utils/AppTheme";
import {cloneModel} from "../../../utils/ModelUtils";
import {useModel} from "../../../utils/UseModel";
import CommonDataTable from "../../CommonDataTable/CommonDataTable";
import {PreconfiguredDataTableProps} from "../../CommonDataTable/PreconfiguredDataTable/PreconfiguredDataTableTypes";

type Props = PreconfiguredDataTableProps<{
    campaign?: DialerCampaignModel,
    contact?: DialerUploadedListPhoneModel,
    additinalFields?: string[]
}>;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            height: `calc(100vh - ${getToolbarTopMargin(5)}px)`
        }
    })
);

const UploadedPhoneListTable: FunctionComponent<Props> = (props) => {
    const {filters, tableId, params, onSelect, breadcrumbs} = props;
    const {campaign, contact, additinalFields = []} = params || {};
    const styles = useStyles();
    const origModel = useModel(DialerCollections.call);

    const uiModel = cloneModel(origModel, [...additinalFields, 'phone_number', 'start_date',
        'end_date', 'call_state', 'call_cause', 'connect_date', 'ringing_date', 'from_start_duration',
        'from_connect_duration', 'ringing_duration']);
    uiModel.meta.delete_if_deps = false;
    uiModel.meta.tableId = tableId;

    const ff = filters || {
        filters: []
    };

    if (campaign) {
        ff.filters.push({
            type: "string",
            value: campaign._id,
            operator: "eq",
            name: "campaign_id"
        });
    }

    if (contact) {
        ff.filters.push({
            type: "string",
            value: contact._id,
            operator: "eq",
            name: "uploaded_list_phone_id"
        });
    }

    uiModel.meta.tableId = tableId;

    return <>
        <CommonDataTable
            type={"table"}
            className={styles.table}
            uiModel={uiModel}
            tableButtons={{}}
            toolbar={breadcrumbs}
            filters={ff}
            previewMode={true}
            readonlyPreview={true}
            onDblClick={(element) => {
                if (onSelect) {
                    onSelect(element);
                }
            }}
        />
    </>
}

export default UploadedPhoneListTable;
