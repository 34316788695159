import {DBElementPermissions, UIModelAction, UIModelSchema} from "@aatdev/common-types";
import {DialerStrategyPhoneRuleType} from "@aatdev/dialer-types";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    IconButton,
    Radio,
    RadioGroup,
    Typography
} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {backend} from "../../../../data/Backend";
import {EditingElement} from "../../../../store/actions/DataActionTypes";
import {clearStateVariable, setStateVariable} from "../../../../store/actions/GeneralActions";
import {AppState} from "../../../../store/StoreTypes";
import {notify} from "../../../../utils/NotifierHelpers";
import {CustomTableActionType} from "../../../CommonDataTable/CommonDataTableTypes";
import {actionUtils} from "../../../Utils/ActionUtils";

const action = (action: UIModelAction, element: EditingElement, schema: UIModelSchema, permissions: DBElementPermissions): CustomTableActionType => {
    return {
        buttonPosition(): "left" | "right" {
            return 'right';
        },
        renderButton: () => {
            return () => {
                const dispatch = useDispatch();
                const {t} = useTranslation();
                useEffect(() => {
                    return () => {
                        dispatch(clearStateVariable(action.action));
                    };
                }, [element]);
                return <IconButton color={"primary"}
                                   onClick={() => dispatch(setStateVariable(action.action, true))}
                                   disabled={!permissions?.edit || !element?.data}
                                   title={t(action.title)}>
                    {actionUtils(action.icon)}
                </IconButton>
            }
        },
        renderCode: () => {
            return () => {
                const classes = useStyles();
                const dispatch = useDispatch();
                const {t} = useTranslation();
                const open = useSelector((state: AppState) => !!state?.general?.variables?.[action.action]);
                const [selected, setSelected] = useState<any>({});
                const [clearAnswered, setClearAnswered] = useState(false);
                const [clearType, setClearType] = useState<string>("selected");

                const handleClose = () => {
                    dispatch(clearStateVariable(action.action));
                }

                const handleConfirm = () => {
                    backend.clearContactStatuses(Object.keys(selected), clearAnswered, element.data.calling_list_id._id,
                        clearType !== 'all' ? element.data._id : undefined)
                        .catch(e => {
                            notify(dispatch, e, "error");
                        }).finally(() => {
                        dispatch(clearStateVariable(action.action));
                    });
                }

                const handleSelected = (name: string, checked: any) => {
                    const s = {...selected};
                    if (!checked) {
                        delete s[name];
                    } else {
                        s[name] = true;
                    }
                    setSelected(s);
                }

                const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                    setClearType((event.target as HTMLInputElement).value);
                };

                return <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>
                        {t('tables:uploaded_list.actions.clear_status.title')}
                    </DialogTitle>
                    <DialogContent>
                        <RadioGroup value={clearType} onChange={handleTypeChange}>
                            <FormControlLabel value="all" control={<Radio/>}
                                              label={t('tables:uploaded_list.actions.clear_status.labels.clear_all')}/>
                            <FormControlLabel value="selected" control={<Radio/>}
                                              label={t('tables:uploaded_list.actions.clear_status.labels.clear_selected')}/>
                        </RadioGroup>
                        <FormControl component="fieldset">
                            <Typography variant="h6" gutterBottom className={classes.statuses}>
                                {t('tables:uploaded_list.actions.clear_status.labels.statuses')}
                            </Typography>
                            <FormGroup>
                                {Object.keys(DialerStrategyPhoneRuleType).map(e => <FormControlLabel
                                    control={<Checkbox checked={!!selected[e]}
                                                       onChange={(event) => handleSelected(e, event.target.checked)}
                                                       name={e}/>}
                                    label={t(`tables:strategy.tabs.phone_rules.phone_codes.${e}`)}
                                />)}
                                <FormControlLabel
                                    control={<Checkbox checked={clearAnswered}
                                                       onChange={(event) => setClearAnswered(event.target.checked)}
                                                       name={'clearCompleted'}/>}
                                    label={t(`tables:uploaded_list.actions.clear_status.labels.answered`)}
                                />
                            </FormGroup>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="secondary" autoFocus>
                            {t('tables:uploaded_list.actions.clear_status.buttons.cancel')}
                        </Button>
                        <Button onClick={handleConfirm} color="primary"
                                disabled={Object.keys(selected).length === 0 && !clearAnswered}>
                            {t('tables:uploaded_list.actions.clear_status.buttons.confirm')}
                        </Button>
                    </DialogActions>
                </Dialog>
            };
        }
    }
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        statuses: {
            marginTop: "1em",
            color: theme.palette.info.main
        }
    })
);

export default action;
