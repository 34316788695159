import {
    DBElementDeps,
    DBElementPermissions,
    SearchResult,
    TableFilters,
    TableSort,
    UIModel,
    UIModels
} from "@aatdev/common-types";
import React from "react";
import {Action} from "redux";
import {PreconfiguredDataTableChainProps} from "../../components/CommonDataTable/PreconfiguredDataTable/PreconfiguredDataTableTypes";
import {ProjectModel} from "../../data/models/ProjectModel";

export enum DataActionType {
    LOAD_ALL_MODELS = "GET_ALL_MODELS",
    UPDATE_TABLE_QUERY_FIELD = "UPDATE_TABLE_QUERY_FIELD",
    UPDATE_TABLE_VARIABLE = "UPDATE_TABLE_VARIABLE",
    UPDATE_TABLE_DATA_FIELD = "UPDATE_TABLE_DATA_FIELD",
    UPDATE_TABLE_CHAIN_FIELD = "UPDATE_TABLE_CHAIN_FIELD",
    UPDATE_FORM_STATE = "UPDATE_FORM_STATE",
    CLOSE_SUB_MENU = "CLOSE_SUB_MENU",
    ADD_SUB_MENU = "ADD_SUB_MENU",
    UPDATE_EDITING_ELEMENT = "UPDATE_EDITING_ELEMENT",
    UPDATE_PART_EDITING_ELEMENT = "UPDATE_PART_EDITING_ELEMENT",
    CLEAR_EDITING_ELEMENT = "CLEAR_EDITING_ELEMENT",
    INIT_MENU = "INIT_MENU",
    REPLACE_MENU = "REPLACE_MENU",
    BLOCK_NAVIGATE = "BLOCK_NAVIGATE",
    CLEAR_TABLE_STATE = "CLEAR_TABLE_STATE",
    SELECT_PROJECT = "SELECT_PROJECT",
}

export type QueryState = {
    filters: TableFilters
    skip: number
    pageSize: number
    showArchived: boolean
    sort?: TableSort
    updated?: boolean
}

export type QueryStateFields = keyof QueryState;

export type BlockedNavigateData = {
    goAction: () => void
} | undefined;

export type TableState = {
    columns_visibility: Record<string, boolean>
    selected?: string
    current_tab?: string
    previewOpen?: boolean
    tableWidth?: number,
    columnOrder?: string[],
    columnWidths?: Record<string, number>
}

export type TableStateFields = keyof TableState;

export type TableChainState = {
    path: PreconfiguredDataTableChainProps[]
}

export type TableChainFields = keyof TableChainState;

export type EditingElementData = any;

export type EditingElement = {
    isNew: boolean
    id: string
    uiModel: UIModel
    data: EditingElementData
    deps: DBElementDeps
    permissions: DBElementPermissions
    createTime: number
    changed?: boolean
    updatedFields: Record<string, boolean>
}

export type DbState = {
    loadedModels: UIModels
    tableQuery: Record<string, QueryState>
    tableState: Record<string, TableState>
    tableData: Record<string, SearchResult>
    tableChain: Record<string, TableChainState>
    formState: Record<string, any>
    menuItems: AppMenuItemsType
    edits: Record<string, EditingElement>
    blockedNavigate?: BlockedNavigateData
    currentProject?: ProjectModel
}

export interface DataAction<T> extends Action<DataActionType> {
    payload: T
}

export type BasicMenuItem = {
    id: string,
    path: string,
    title: string,
    component: React.ComponentClass<any, any> | React.FunctionComponent<any>,
    root?: boolean,
}

export type DataMenuItem = {
    uiModel: UIModel,
} & BasicMenuItem;

export type TableMenuItem = {
    type: 'table',
    group: string,
} & DataMenuItem;

export type TableSubMenuItem = {
    type: 'sub',
    elementId: string,
    parentId: string
} & DataMenuItem;

export type FormMenuItem = {
    type: 'form',
    group: string,
} & DataMenuItem;

export type AppMenuItemsType = Array<TableMenuItem | TableSubMenuItem | FormMenuItem>;

export type DataThunkCallback = (success: boolean) => void;

export type LoadModelsResponse = {
    models: UIModels,
    menu: Array<{
        title: string,
        items: (string | { component: string, title: string })[]
    }>
}

export type ReduxStateChangeFunc = (field: string, value: any, op: 'update' | 'add' | 'delete') => void;
