import {StompCampaignStatMsg, StompProjectStatMsg} from "@aatdev/dialer-types";
import {StatsAction} from "../reducers/StatsReducer";
import {StatsActionType} from "./StatsActionTypes";

export const updateProjectStat = (data: StompProjectStatMsg): StatsAction<any> => ({
    type: StatsActionType.UPDATE_PROJECT_STAT,
    payload: data
});

export const updateCampaignStat = (data: StompCampaignStatMsg): StatsAction<any> => ({
    type: StatsActionType.UPDATE_CAMPAIGN_STAT,
    payload: data
});
