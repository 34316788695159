"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DialerUploadedListPhoneState = void 0;
var DialerUploadedListPhoneState;
(function (DialerUploadedListPhoneState) {
    DialerUploadedListPhoneState["none"] = "none";
    DialerUploadedListPhoneState["incall"] = "incall";
    DialerUploadedListPhoneState["processing"] = "processing";
    DialerUploadedListPhoneState["finished"] = "finished";
    DialerUploadedListPhoneState["canceled"] = "canceled";
})(DialerUploadedListPhoneState = exports.DialerUploadedListPhoneState || (exports.DialerUploadedListPhoneState = {}));
