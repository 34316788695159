import {NotiferActionType, NotificationType, NotifierAction} from "../actions/NotiferActionTypes";

export type NotiferStoreState = {
    notifications: NotificationType[]
}

const defaultState: NotiferStoreState = {
    notifications: [],
};

export const notiferReduser = (state = defaultState, action: NotifierAction) => {
    const payload = action.payload;
    switch (action.type) {
        case NotiferActionType.ENQUEUE_SNACKBAR:
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        key: payload.key,
                        ...payload.notification,
                    },
                ],
            };

        case NotiferActionType.CLOSE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.map(notification => (
                    (payload.dismissAll || notification.key === payload.key)
                        ? {...notification, dismissed: true}
                        : {...notification}
                )),
            };

        case NotiferActionType.REMOVE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.filter(
                    notification => notification.key !== payload.key,
                ),
            };

        default:
            return state;
    }
};
