import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import backend from "i18next-http-backend";
import {initReactI18next} from 'react-i18next';
import {getURLFromEnv} from "./utils/MiscUtils";

export const init18n = () => {
    return i18n
        .use(backend)
        // detect user language
        // learn more: https://github.com/i18next/i18next-browser-languageDetector
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            debug: process.env.NODE_ENV === 'development',
            fallbackLng: "en-us",
            keySeparator: ".",
            interpolation: {
                escapeValue: false // react already safes from xss
            },
            ns: ["tables", "common", "dialogs", "forms", "validate", "redux"],
            react: {
                useSuspense: false
            },
            backend: {
                loadPath: `${getURLFromEnv("REACT_APP_API_BACKEND_URL")}/api/i18n/{{ns}}/{{lng}}`,
                allowMultiLoading: false,
                requestOptions: {
                    credentials: 'include'
                },
            }
        });
}

export const fixedT = (ns: string | null = null, prefix: string | undefined = undefined) => i18n.getFixedT(null, ns, prefix);
