import {StompCampaignStatMsg} from "@aatdev/dialer-types";
import {Avatar, Card, CardContent, CardHeader, Grid} from "@material-ui/core";
import {red} from "@material-ui/core/colors";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {CallStatisticsCard} from "../Utils/CallStatisticsCard";


type CampaignCardProps = {
    campaignName: string
    data: StompCampaignStatMsg
    hideHeader?: boolean
}

export const DashboardCampaignCard: FunctionComponent<CampaignCardProps> = (props) => {
    const {data, campaignName, hideHeader} = props;
    const classes = useStyles();
    const {t} = useTranslation();
    return <Card>
        {!hideHeader && <CardHeader
            avatar={
                <Avatar aria-label="recipe" className={classes.avatar}>
                    {campaignName[0]}
                </Avatar>
            }
            subheader={`${t('forms:stats.campaign.usedOutTrunks')} ${data.usedOutTrunks}, ${t('forms:stats.campaign.usedAgentTrunks')} ${data.usedAgentTrunks}`}
            title={campaignName}
        />}
        <CardContent>
            <Grid container spacing={1} direction="row">
                <Grid item xs={6}>
                    <CallStatisticsCard header={t('forms:stats.campaign.summary.headers.total')}
                                        totalData={data.total}/>
                </Grid>
                <Grid item xs={6}>
                    <CallStatisticsCard header={t('forms:stats.campaign.summary.headers.last30')}
                                        totalData={data.last30}/>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            backgroundColor: red[500],
        }
    })
)

export default DashboardCampaignCard;

