import {DbState, ReduxStateChangeFunc} from "./DataActionTypes";
import {deleteInnerField, pushField, updateInnerField} from "../../utils/ModelUtils";
import {createValidateValue} from "../../utils/ValidatedValueUtils";

export const updateTableStateField = (state: DbState, stateName: keyof DbState, path: string[], data: any) => {
    return updateInnerField(state, [stateName, ...path], data);
}

export const createReduxStateChangeFunc = (onChange: (data: any) => any): ReduxStateChangeFunc => (field: string, value: any, op: 'update' | 'add' | 'delete') => {
    onChange((data: any) => {
        if (op === "delete") {
            return deleteInnerField(data, field.split('.'));
        } else if (op === "add") {
            return pushField(data, field.split('.'), value, ((a, b) => {
                return b.type.value.length - a.type.value.length;
            }));
        } else {
            // wrap a scalar value in validated wrapper
            if (typeof value === "string" || typeof value === "number" || typeof value === "boolean") {
                value = createValidateValue(value, true);
            }
            return updateInnerField(data, field.split('.'), value);
        }
    });
}
