import {UIModel} from "@aatdev/common-types";
import '@aatdev/reactdatagrid/index.css'
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import React, {FunctionComponent} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router";
import {deleteElementThunk, openFormEditorThunk} from "../../store/actions/DataActions";
import {getToolbarTopMargin} from "../../utils/AppTheme";
import {useCurrentMenuItem} from "../../utils/UseCurrentMenuItem";
import CommonDataTable from "../CommonDataTable/CommonDataTable";

type Props = {
    uiModel: UIModel
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            height: `calc(100vh - ${getToolbarTopMargin(2.2)}px)`
        }
    })
);

const DataTable: FunctionComponent<Props> = (props: Props) => {
    const {uiModel} = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const menuState = useCurrentMenuItem();
    const styles = useStyles();
    const {t} = useTranslation();

    return (
        <CommonDataTable
            type={"table"}
            previewMode={true}
            className={styles.table}
            uiModel={uiModel}
            tableButtons={{
                ...(uiModel.meta.editable && {
                    create: {
                        label: t("common:buttons.add"),
                        handler: (id => menuState && dispatch(openFormEditorThunk(uiModel.meta.collection, id, navigate, false)))
                    },
                    edit: {
                        label: t("common:buttons.edit"),
                        handler: (id => menuState && dispatch(openFormEditorThunk(uiModel.meta.collection, id, navigate, false)))
                    },
                    remove: {
                        label: t("common:buttons.delete"),
                        handler: (id, cb) => dispatch(deleteElementThunk(uiModel.meta, id, cb))
                    },
                    copy: {
                        label: t("common:buttons.copy"),
                        handler: (id => menuState && dispatch(openFormEditorThunk(uiModel.meta.collection, id, navigate, true)))
                    }
                })
            }}
        />
    );
};

export default DataTable;
