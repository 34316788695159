import {Grid} from "@material-ui/core";
import {FunctionComponent} from "react";
import {useSelector} from "react-redux";
import {AppState} from "../../../store/StoreTypes";
import {DashboardCampaignCard} from "./DashboardCampaignCard";

type Props = {}

const Dashboard: FunctionComponent<Props> = (props) => {
    const campaignStat = useSelector((state: AppState) => state.stats.campaignStat);

    return <Grid
        container
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="flex-start">
        {Object.values(campaignStat)
            .map(item => <Grid key={item.campaignId} item xs={6} sm={4}>
                <DashboardCampaignCard data={campaignStat[item.campaignId]} campaignName={item.campaignName}/>
            </Grid>)}
    </Grid>;
}

export default Dashboard;
