"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DialerCampaignLimitOperands = exports.DialerCampaignLimits = void 0;
var DialerCampaignLimits;
(function (DialerCampaignLimits) {
    DialerCampaignLimits["total_calls"] = "total_calls";
    DialerCampaignLimits["answered_calls"] = "answered_calls";
    DialerCampaignLimits["error_calls"] = "error_calls";
    DialerCampaignLimits["not_answered_calls"] = "not_answered_calls";
    DialerCampaignLimits["busy_calls"] = "busy_calls";
    DialerCampaignLimits["total_duration"] = "total_duration";
    DialerCampaignLimits["completed_contacts"] = "completed_contacts";
    DialerCampaignLimits["total_contacts"] = "total_contacts";
})(DialerCampaignLimits = exports.DialerCampaignLimits || (exports.DialerCampaignLimits = {}));
var DialerCampaignLimitOperands;
(function (DialerCampaignLimitOperands) {
    DialerCampaignLimitOperands["eq"] = "eq";
    DialerCampaignLimitOperands["neq"] = "neq";
    DialerCampaignLimitOperands["gt"] = "gt";
    DialerCampaignLimitOperands["lt"] = "lt";
    DialerCampaignLimitOperands["gte"] = "gte";
    DialerCampaignLimitOperands["lte"] = "lte";
})(DialerCampaignLimitOperands = exports.DialerCampaignLimitOperands || (exports.DialerCampaignLimitOperands = {}));
