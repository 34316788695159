import {AppointmentForm as AppointmentFormBase} from "@devexpress/dx-react-scheduler";
import {FunctionComponent} from "react";
import {AppointmentForm} from "@devexpress/dx-react-scheduler-material-ui";

type TextEditorProps = AppointmentFormBase.TextEditorProps;

export const ScheduleTextEditor: FunctionComponent<TextEditorProps> = (props) => {
    if (props.type === 'multilineTextEditor') {
        return null;
    }
    return <AppointmentForm.TextEditor {...props} />;
};
