import {UIModelFieldSelectItem, UIModelFieldType, ValidatedValue} from "@aatdev/common-types";
import {DialerStrategyMoveToNextGroupType, DialerStrategyPhoneRuleType} from "@aatdev/dialer-types";
import {Box, Button, Card, CardContent, IconButton, makeStyles, Tab, Tabs} from "@material-ui/core";
import {createStyles, Theme, withStyles} from "@material-ui/core/styles";
import {Add, Delete, DeleteOutline} from "@material-ui/icons";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {v4} from "uuid";
import {updateInnerField} from "../../../utils/ModelUtils";
import {createValidateValue} from "../../../utils/ValidatedValueUtils";
import {DataFormProps} from "../../DataEditor/DataForm/DataFormTypes";
import DataInput from "../../DataEditor/DataForm/DataInput";
import ListEditor, {ListEditorComponentType} from "../../Utils/ListEditor";
import {
    DialPhoneRuleGroupValidated,
    DialPhoneRulesValidated,
    DialPhoneRuleValidated
} from "../Data/Models/StrategyValidated";
import AttemptsEditor from "../Utils/AttemptsEditor";

type StrategyPhoneRulesProps = {} & DataFormProps;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "row"
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        minHeight: 500
    },
    tabPanel: {
        marginTop: "1em",
        width: 280
    },
    details: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        marginTop: "1em"
    },
    title: {
        alignSelf: "center",
        textAlign: "center"
    },
    closeIcon: {
        marginRight: "1em"
    },
    content: {
        margin: "1em",
        width: "100%"
    },
    ruleGroupToolbar: {
        display: 'flex',
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        backgroundColor: theme.palette.background.default
    },
    marginLeft: {
        marginLeft: "3em"
    }
}));

const StyledTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
        backgroundColor: '#1890ff',
    },
})(Tabs);

const StyledTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            textTransform: 'none',
            minWidth: 72,
            marginRight: theme.spacing(4),
            '&:hover': {
                color: '#40a9ff',
                opacity: 1,
            },
            '&$selected': {
                color: '#1890ff',
                fontWeight: theme.typography.fontWeightMedium,
                backgroundColor: theme.palette.background.default
            },
            '&:focus': {
                color: '#40a9ff',
            }
        },
        wrapper: {
            flexDirection: "row"
        },
        selected: {},
    }),
)((props: any) => <Tab disableRipple {...props} />);

const StrategyPhoneRules: FunctionComponent<StrategyPhoneRulesProps> = (props: StrategyPhoneRulesProps) => {
    const {onChange, fieldPrefix, element} = props;
    const {data, permissions} = element;
    const [currentTab, setCurrentTab] = useState("");

    const tabData: DialPhoneRulesValidated = data[fieldPrefix] || {
        list: [],
        max_attempts: 3
    };
    tabData.list = tabData.list || [];

    useEffect(() => {
        if (tabData.list.length === 0) {
            tabData.list.push(getDefaultPhoneRule());
        }
        setCurrentTab(tabData.list[0].id);
    }, [element.id]);

    const styles = useStyles();
    const {t} = useTranslation();

    const handleChange = (field: string) => (value: any) => {
        const val = value?.target?.value || value;
        onChange((data: any) => {
            return updateInnerField(data, field.split('.'), val);
        });
    }

    const handleAddTab = () => {
        onChange({
            list: [
                ...tabData.list,
                getDefaultPhoneRule()
            ]
        });
    }

    const handleDeleteTab = (id: string) => {
        const l = [...tabData.list];
        l.splice(l.findIndex(e => e.id === id), 1);
        onChange({
            list: l
        });
        setCurrentTab(l[0].id);
    }

    const getDefaultPhoneRule = (): DialPhoneRuleValidated => {
        return {
            id: v4(),
            rule_groups: [
                getDefaultRuleGroupRow()
            ]
        }
    }

    const getDefaultRuleGroupRow = (): DialPhoneRuleGroupValidated => {
        const getType = () => ({
            max_attempts: createValidateValue(1, true),
            intervals: []
        });
        return {
            id: v4(),
            max_attempts: createValidateValue(3, true),
            by_type: {
                busy: getType(),
                error: getType(),
                noanswer: getType(),
                not_available: getType(),
            },
            move_to_next_group_after: {
                type: createValidateValue(DialerStrategyMoveToNextGroupType.day, true),
                amount: createValidateValue(1, true),
                after_time: createValidateValue(new Date(0, 0, 0, 0, 0, 0), true),
                timezone: createValidateValue(new Date().getTimezoneOffset(), true),
            }
        }
    }

    const helpPrefix = [element.uiModel.meta.collection, fieldPrefix];


    return <div className={styles.root}>
        <div className={styles.tabPanel}>
            <DataInput
                config={
                    {
                        type: UIModelFieldType.int,
                        title: t("tables:strategy.tabs.phone_rules.max_attempts"),
                        field: "max_attempts"
                    }
                }
                constrains={"greaterThan0"}
                value={tabData?.max_attempts}
                default={3}
                onChange={handleChange("max_attempts")}
                style={{width: "20em"}}
                disabled={!permissions.edit}
                helpId={[...helpPrefix, "max_attempts"]}
            />
            <Button onClick={handleAddTab} fullWidth>
                <Add/>
            </Button>
            <StyledTabs value={currentTab}
                        onChange={(e, v) => setCurrentTab(v)}
                        variant="scrollable"
                        scrollButtons="auto"
                        orientation="vertical">
                {tabData.list.map((tab, i) => {
                    const icon = <Button key={i}
                                         onClick={() => handleDeleteTab(tab.id)}
                                         disabled={tabData.list.length < 2}>
                        <DeleteOutline className={styles.closeIcon}/>
                    </Button>;
                    return (
                        <StyledTab key={tab.id}
                                   value={tab.id}
                                   label={"Phone " + (i + 1)}
                                   icon={icon}
                        />
                    )
                })}
            </StyledTabs>
        </div>

        <div className={styles.content}>
            {tabData.list.map((e, i) => currentTab === e.id && <Box>
                <ListEditor
                    key={e.id}
                    defaultListValue={getDefaultRuleGroupRow()}
                    listFieldName={`list.${i}.rule_groups`}
                    listEditorComponent={RulesEditor}
                    data={tabData}
                    readonly={!permissions.edit}
                    onChange={onChange}
                    helpPrefix={helpPrefix}
                />
            </Box>)}
        </div>
    </div>;
}

type RulesEditorProps = ListEditorComponentType<DialPhoneRuleGroupValidated>;

const RulesEditor: FunctionComponent<RulesEditorProps> = (props: RulesEditorProps) => {
    const {onDelete, readonly, row, onChange, helpPrefix = [], index, total} = props;
    const styles = useStyles();
    const {t} = useTranslation("tables");
    return <Card className={styles.details}>
        <CardContent style={{flex: 1}}>
            <div className={styles.ruleGroupToolbar}>
                <span style={{flex: 1, display: "flex"}}>
                    {index < total - 1 && <>
                        <DataInput
                            config={
                                {
                                    type: UIModelFieldType.int,
                                    title: t("tables:strategy.tabs.phone_rules.max_attempts"),
                                    field: "max_attempts"
                                }
                            }
                            constrains={"greaterThan0"}
                            value={row.max_attempts}
                            default={3}
                            onChange={value => onChange(["max_attempts"], value)}
                            style={{width: "20em"}}
                            disabled={readonly}
                            helpId={[...helpPrefix, "max_attempts"]}
                        />
                        <DataInput
                            config={
                                {
                                    type: UIModelFieldType.select,
                                    title: t("tables:strategy.tabs.phone_rules.move_to_next_group_after.type.title"),
                                    field: "type",
                                    disableClearable: true,
                                    items: Object.values(DialerStrategyMoveToNextGroupType).reduce((pv: Record<string, UIModelFieldSelectItem>, cv) => {
                                        pv[cv] = {
                                            label: t(`strategy.tabs.phone_rules.move_to_next_group_after.type.values.${cv}`)
                                        };
                                        return pv;
                                    }, {})
                                }
                            }
                            default={"day"}
                            value={row.move_to_next_group_after?.type}
                            onChange={value => onChange(["move_to_next_group_after", "type"], value)}
                            style={{width: "20em", marginLeft: "3em"}}
                            disabled={readonly}
                            helpId={[...helpPrefix, "move_to_next_group_after.type"]}
                        />
                        <DataInput
                            config={
                                {
                                    type: UIModelFieldType.int,
                                    title: t("tables:strategy.tabs.phone_rules.move_to_next_group_after.amount.title"),
                                    field: "amount"
                                }
                            }
                            className={styles.marginLeft}
                            constrains={"greaterThan0"}
                            value={row.move_to_next_group_after?.amount}
                            default={1}
                            onChange={value => onChange(["move_to_next_group_after", "amount"], value)}
                            style={{width: "10em", marginLeft: "1em"}}
                            disabled={readonly}
                            helpId={[...helpPrefix, "move_to_next_group_after.amount"]}
                        />
                        <DataInput
                            config={
                                {
                                    type: UIModelFieldType.time,
                                    title: t("tables:strategy.tabs.phone_rules.move_to_next_group_after.after_time.title"),
                                    field: "after_time"
                                }
                            }
                            value={row.move_to_next_group_after?.after_time}
                            onChange={(value: ValidatedValue<Date>) => {
                                onChange(["move_to_next_group_after", "after_time"], value);
                                onChange(["move_to_next_group_after", "timezone"], createValidateValue(value.value.getTimezoneOffset(), true))
                            }}
                            style={{width: "10em", marginLeft: "1em"}}
                            disabled={readonly}
                            helpId={[...helpPrefix, "after_time"]}
                        />
                    </>}
                </span>
                {index + 1 === total && total > 1 &&
                <IconButton color={"secondary"} onClick={onDelete} disabled={readonly}>
                    <Delete/>
                </IconButton>}
            </div>
            {Object.values(DialerStrategyPhoneRuleType).map(type => {
                return <AttemptsEditor
                    key={type}
                    onChange={(data) => {
                        onChange(["by_type", type], {
                            max_attempts: data.count,
                            intervals: data.values
                        });
                    }}
                    labels={{
                        count: `tables:strategy.tabs.phone_rules.phone_codes.${type}`,
                        intervals: "tables:strategy.tabs.phone_rules.intervals",
                        attempt: "tables:strategy.tabs.phone_rules.attempt"
                    }}
                    count={row.by_type[type]?.max_attempts}
                    values={row.by_type[type]?.intervals}
                    defaultInterval={10}
                    readonly={readonly}
                />;
            })}
        </CardContent>
    </Card>;
}


export default StrategyPhoneRules;
