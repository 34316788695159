import {
    DBElement,
    DBElementPermissions,
    ElementWithDeps,
    HelpModel,
    SearchResult,
    WhiteLabelType
} from "@aatdev/common-types";
import {DialerCampaignCurrentCounters, LANGUAGE_HEADER_NAME, TZ_OFFSET_HEADER_NAME} from "@aatdev/dialer-types";
import axios, {AxiosResponse} from "axios";
import LocalStoreService from "../services/LocalStoreService";
import {QueryState} from "../store/actions/DataActionTypes";
import {changeLoggedStateAction} from "../store/actions/GeneralActions";
import {store} from "../store/RootStore";
import {getURLFromEnv, parseDate} from "../utils/MiscUtils";
import MQClient from "./MQClient";

export const HTTP_TIMEOUT = 120000;

axios.defaults.withCredentials = true;

type backendType = {
    login: (username: string, password: string) => Promise<any>
    logout: () => Promise<any>
    profile: () => Promise<any>
    checkUnique: (elementType: string, _id: string, field: string, value: string) => Promise<AxiosResponse<{
        unique: boolean
    }>>
    getById: (elementType: string, id: string, version: number, returnEmpty?: boolean) => Promise<AxiosResponse<ElementWithDeps<any>>>
    update: (elementType: string, body: any) => Promise<AxiosResponse<ElementWithDeps<any>>>
    delete: (elementType: string, body: any) => Promise<AxiosResponse<any>>
    copy: (elementType: string, id: string) => Promise<AxiosResponse<DBElement>>
    getUIModels: (projectId: string) => Promise<any>
    tableQuery: (elementType: string, updateTime: number, params: QueryState, type: 'select' | 'list') => Promise<SearchResult>
    freeQuery: (elementType: string, updateTime: number, params: any) => Promise<SearchResult>
    getPermissions: (elementType: string) => Promise<AxiosResponse<DBElementPermissions>>
    exportProject: (projectId: string, collections: string[]) => Promise<AxiosResponse<any>>
    importProject: (projectId: string, collections: string[], file: any) => Promise<AxiosResponse<ElementWithDeps<any>>>
    trunkStatus: (name: string) => Promise<string>
    validate: (elementType: string, data: any) => Promise<Record<string, any>>
    clearContactStatuses: (statuses: string[], clearAnswered: boolean, callingListId: string, uploadedListId?: string) => Promise<AxiosResponse<any>>
    exportTable: (elementType: string, params: QueryState, fields: any, clientWidth?: number) => Promise<AxiosResponse<any>>
    getHelpData: (helpId: string) => Promise<AxiosResponse<HelpModel>>
    saveHelpData: (helpId: string, type: 'header' | 'content', text: string) => Promise<AxiosResponse<void>>
    getSystemInfo: () => Promise<AxiosResponse<void>>,
    getTheme: () => Promise<WhiteLabelType>,
    getUploadListStat: (id: string) => Promise<DialerCampaignCurrentCounters>
}

const axisBackend = axios.create({
    baseURL: getURLFromEnv("REACT_APP_API_BACKEND_URL"),
    timeout: HTTP_TIMEOUT
});

const useCommonInterceptors = (config: any) => {
    config.withCredentials = true;
    config.headers[LANGUAGE_HEADER_NAME] = LocalStoreService.get("i18nextLng");
    config.headers[TZ_OFFSET_HEADER_NAME] = new Date().getTimezoneOffset();
    return config;
};

function handleDates(body: any) {
    if (body === null || body === undefined || typeof body !== "object")
        return;

    for (const key of Object.keys(body)) {
        const value = body[key];
        if (typeof value === "string") {
            body[key] = parseDate(value);
        } else if (typeof value === "object") {
            handleDates(value);
        }
    }
}

axisBackend.interceptors.request.use(useCommonInterceptors);

axisBackend.interceptors.response.use(function (response) {
    handleDates(response.data);
    return response;
}, function (error) {
    if (error?.response?.status === 401) {
        MQClient.disconnect();
        store.dispatch(changeLoggedStateAction(undefined));
    } else {
        return Promise.reject(error)
    }
});

export const backend: backendType = {
    login: async (username: string, password: string) => {
        return await axisBackend.post('/api/auth/login', {username: username, password: password});
    },
    logout: async () => {
        return await axisBackend.post('/api/auth/logout', {});
    },
    profile: async () => {
        return await axisBackend.get('/api/auth/profile', {});
    },
    checkUnique: async (elementType: string, _id: string, field: string, value: any) => {
        const projectId = LocalStoreService.get("current-project-id");
        return await axisBackend.post(`/api/${projectId}/${elementType}/unique`, {
            _id: _id,
            field: field,
            value: value
        });
    },
    getById: async (elementType: string, id: string, version = -1, returnEmpty) => {
        const projectId = LocalStoreService.get("current-project-id");
        return await axisBackend.get(`/api/${projectId}/${elementType}/${id}?version=${version}&re=${returnEmpty}`);
    },
    update: async (elementType: string, body: any) => {
        const projectId = LocalStoreService.get("current-project-id");
        return await axisBackend.post(`/api/${projectId}/${elementType}`, body);
    },
    delete: async (elementType: string, body: any) => {
        const projectId = LocalStoreService.get("current-project-id");
        return await axisBackend.delete(`/api/${projectId}/${elementType}/${body}`);
    },
    copy: async (elementType: string, id: string) => {
        const projectId = LocalStoreService.get("current-project-id");
        return await axisBackend.get(`/api/${projectId}/${elementType}/copy/${id}`);
    },
    getUIModels: async (projectId) => {
        return await axisBackend.get(`/api/${projectId}/addons/models`);
    },
    tableQuery: async (elementType: string, updateTime: number, query: QueryState, type: 'select' | 'list' = 'list') => {
        const projectId = LocalStoreService.get("current-project-id");
        return (await axisBackend.post(`/api/${projectId}/${elementType}/${type}`, {
            updateTime,
            query
        }))?.data || {};
    },
    freeQuery: async (elementType: string, updateTime: number, query: any) => {
        const projectId = LocalStoreService.get("current-project-id");
        return (await axisBackend.post(`/api/${projectId}/${elementType}/list`, {
            updateTime,
            query
        }))?.data || {};
    },
    getPermissions: async (elementType: string) => {
        const projectId = LocalStoreService.get("current-project-id");
        return await axisBackend.get(`/api/${projectId}/${elementType}/permissions`);
    },
    exportProject: async (projectId: string, collections: string[]) => {
        return await axisBackend.post(`/api/${projectId}/campaign/export`, {
                collections: collections,
                project_id: projectId
            },
            {responseType: 'blob'});
    },
    importProject: async (projectId: string, collections: string[], file: any) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('params', JSON.stringify({
            project_id: projectId,
            collections: collections
        }))
        return await axisBackend.post(`/api/${projectId}/campaign/import`, formData, {
            headers: {'Content-Type': 'multipart/form-data'}
        });
    },
    trunkStatus: async (id: string) => {
        const res = await axisBackend.get(`/api/fs/profileStatus/${id}`);
        if (res.data) {
            return res.data.state
        }
        return "undefined";
    },
    validate: async (elementType: string, data: any) => {
        const projectId = LocalStoreService.get("current-project-id");
        return (await axisBackend.post(`/api/${projectId}/${elementType}/validate`, data)).data;
    },
    clearContactStatuses: async (statuses, clearAnswered: boolean, callingListId, uploadedListId) => {
        const projectId = LocalStoreService.get("current-project-id");
        return await axisBackend.post(`/api/${projectId}/uploaded_list/clear_statuses`, {
            statuses,
            clearAnswered,
            callingListId,
            uploadedListId
        });
    },
    exportTable: async (elementType: string, query: QueryState, fields: any, clientWidth?: number) => {
        const projectId = LocalStoreService.get("current-project-id");
        return (await axisBackend.post(`/api/${projectId}/${elementType}/export_table`, {
            query,
            fields,
            clientWidth
        }, {
            responseType: 'blob'
        }));
    },
    getHelpData: async (helpId: string) => {
        return await axisBackend.get(`/api/help/${helpId}`);
    },
    saveHelpData: async (helpId: string, type: 'header' | 'content', text: string) => {
        return await axisBackend.post(`/api/help/${helpId}`, {
            [type]: text
        });
    },
    getSystemInfo: async () => {
        return await axisBackend.get(`/api/system/version`);
    },
    getTheme: async () => {
        return (await axisBackend.get(`/api/white_label`)).data;
    },
    getUploadListStat: async (id: string) => {
        const projectId = LocalStoreService.get("current-project-id");
        return (await axisBackend.get(`/api/${projectId}/uploaded_list/stat/${id}`)).data;
    }
}
