import {
    Collapse,
    createStyles,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
    makeStyles,
    Theme
} from "@material-ui/core";
import React, {FunctionComponent} from "react";
import CloseIcon from "@material-ui/icons/Close";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {getToolbarTopMargin} from "../../utils/AppTheme";
import {Label, StarBorder} from "@material-ui/icons";
import {useCurrentMenuItem} from "../../utils/UseCurrentMenuItem";
import {useDispatch, useSelector} from "react-redux";
import {AppMenuItemsType, DataMenuItem, TableSubMenuItem} from "../../store/actions/DataActionTypes";
import {AppState} from "../../store/StoreTypes";
import {closeFormEditorThunk} from "../../store/actions/DataActions";
import {useBlockNavigate} from "../../utils/UseBlockNavigate";

type OwnProps = {
    open: boolean,
    onClose: () => void,
    menu: AppMenuItemsType,
    width: number
}

type Props = OwnProps;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            flexShrink: 0
        },
        drawerPaper: {
            marginTop: getToolbarTopMargin(),
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        },
        nestedMenu: {
            paddingLeft: theme.spacing(4),
        },
    }),
);

const MainDrawer: FunctionComponent<Props> = (props: Props) => {
    const {open, onClose, menu = [], width} = props;
    const navigate = useBlockNavigate();
    const classes = useStyles();
    const dispatch = useDispatch();
    const routeState = useCurrentMenuItem();
    const edits = useSelector((state: AppState) => state.db.edits);

    const handleDrawerClick = (route: DataMenuItem) => {
        navigate(route.path);
    }

    const handleCloseClick = (route: TableSubMenuItem) => {
        dispatch(closeFormEditorThunk(route.id, navigate));
    }

    let currentGroup = "";

    return (<Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        PaperProps={{
            style: {
                width: width
            }
        }}
        classes={{
            paper: classes.drawerPaper,
        }}>
        <div className={classes.drawerHeader}>
            <IconButton onClick={onClose}>
                <ChevronLeftIcon/>
            </IconButton>
        </div>
        <Divider/>
        <List>
            {menu.filter(e => e.root)
                .map((route: any) => {
                    const subItems = menu.filter(a => a.type === 'sub' && a.parentId === route.id) as TableSubMenuItem[];
                    let groupTitle = "";
                    if (currentGroup !== route.group) {
                        currentGroup = route.group;
                        groupTitle = route.group;
                    }
                    return <React.Fragment key={route.id}>
                        {groupTitle && <ListSubheader>{groupTitle}</ListSubheader>}
                        <ListItem button onClick={() => handleDrawerClick(route)}
                                  selected={routeState?.id === route.id}>
                            <ListItemIcon>
                                <Label/>
                            </ListItemIcon>
                            <ListItemText primary={route.title}/>
                        </ListItem>
                        {subItems.length > 0 && <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {subItems.map(sub =>
                                    <ListItem key={sub.id} button className={classes.nestedMenu}
                                              onClick={() => handleDrawerClick(sub)}
                                              selected={routeState?.id === sub.id}>
                                        <ListItemIcon>
                                            <StarBorder/>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={edits[sub.id]?.data?.name?.value ? edits[sub.id].data?.name?.value : sub.title}/>
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" aria-label="delete"
                                                        onClick={() => handleCloseClick(sub)}>
                                                <CloseIcon/>
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )}
                            </List>
                        </Collapse>}
                    </React.Fragment>
                })}
        </List>
    </Drawer>)
}

export default MainDrawer;
