import {LicenseData} from "@aatdev/common-types";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Typography
} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import React, {FunctionComponent, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {backend} from "../../data/Backend";
import {AppState} from "../../store/StoreTypes";
import {WhiteLabelContext} from "../Utils/WhiteLabelProvider";


type Props = {
    open: boolean,
    onClose: (yes: boolean) => void
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        about: {
            fontSize: "1.1rem",
            marginBottom: "0.5em",
            color: theme.palette.text.primary
        }
    })
);

const AboutDialog: FunctionComponent<Props> = (props: Props) => {
    const styles = useStyles();
    const {t} = useTranslation();
    const whiteLabel = useContext(WhiteLabelContext);
    const [systemInfo, setSystemInfo] = useState<{
        ui: {
            version: string,
            buildDate: string
        },
        dialer: {
            version: string,
            buildDate: string
        },
        license: LicenseData
    } | undefined>();
    const user = useSelector((state: AppState) => state.general.user);

    useEffect(() => {
        if (props.open) {
            backend.getSystemInfo().then(value => {
                setSystemInfo(value.data as any);
            });
        }
    }, [props.open]);

    return (<>
            <Dialog open={props.open} onClose={() => props.onClose(false)} maxWidth={"lg"}>
                <DialogTitle style={{cursor: 'move'}}>
                    {t('dialogs:about.title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography className={styles.about}>
                            {t(whiteLabel?.text?.about || 'common:toolbar.title', 'Dial')}
                        </Typography>
                        <Grid container>
                            <Grid item xs={6}>{t('dialogs:about.userName')}</Grid>
                            <Grid item xs={6}>{user?.userName}</Grid>
                            <Grid item xs={6}>{t('dialogs:about.ui.version')}</Grid>
                            <Grid item xs={6}>{systemInfo?.ui?.version}</Grid>
                            <Grid item xs={6}>{t('dialogs:about.ui.buildDate')}</Grid>
                            <Grid item xs={6}>{`${systemInfo?.ui?.buildDate}`}</Grid>
                            <Grid item xs={6}>{t('dialogs:about.dialer.version')}</Grid>
                            <Grid item xs={6}>{systemInfo?.dialer?.version}</Grid>
                            <Grid item xs={6}>{t('dialogs:about.dialer.buildDate')}</Grid>
                            <Grid item xs={6}>{`${systemInfo?.dialer?.buildDate}`}</Grid>
                        </Grid>
                        {
                            systemInfo?.license?.attributes ?
                                <Grid container>
                                    <Grid item xs={6}>{t('dialogs:about.license.startDate')}</Grid>
                                    <Grid item xs={6}>{systemInfo?.license?.startDate}</Grid>
                                    <Grid item xs={6}>{t('dialogs:about.license.expireDate')}</Grid>
                                    <Grid item xs={6}>{systemInfo?.license?.expireDate}</Grid>
                                    {Object.keys(systemInfo.license.attributes)
                                        .map((key: any) => <React.Fragment key={key}>
                                            <Grid item xs={6}>{t(`dialogs:about.license.${key}`)}</Grid>
                                            <Grid item xs={6}>{(systemInfo.license.attributes as any)[key]}</Grid>
                                        </React.Fragment>)}
                                    <Grid item xs={6}>{t('dialogs:about.license.hostId')}</Grid>
                                    <Grid item xs={6} style={{overflowX: "scroll"}}>{systemInfo?.license?.hostId}</Grid>
                                </Grid> : <Typography>{t('dialogs:about.license.not_licensed')}</Typography>
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => props.onClose(false)} color="primary">
                        {t("common:buttons.close")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default AboutDialog;
