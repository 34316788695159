"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertDatesAccordingToTimezones = exports.getTimezoneOffset = exports.jsonBodyReviver = exports.parseDate = exports.convertTimeZone = void 0;
const defaultDateRegexp = () => /^\d{4}-\d{2}-\d{2}(\s\d{2}(:\d{2}){0,2})?$/;
const isoDateRegexp = () => /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
const convertTimeZone = (dt, utcOffset, localOffset) => {
    const date = new Date(dt.getTime());
    if (localOffset === undefined)
        localOffset = date.getTimezoneOffset();
    const tzMinToMs = (minutes) => {
        return minutes * 60 * 1000;
    };
    date.setTime(date.getTime() - tzMinToMs(localOffset) + tzMinToMs(utcOffset));
    return date;
};
exports.convertTimeZone = convertTimeZone;
const parseDate = (value, regexp = defaultDateRegexp(), utcOffset) => {
    if (value && typeof value === 'string') {
        value = value.trim();
        const match = value.match(regexp);
        if (match) {
            /* if value === YYYY-MM-DD */
            if (value.length === 10) {
                value = value + ' 00:00:00';
            }
            let valueDate = new Date(value);
            if (!Number.isNaN(valueDate.getTime())) {
                if (utcOffset !== undefined) {
                    valueDate = (0, exports.convertTimeZone)(valueDate, utcOffset);
                }
                return valueDate;
            }
        }
        else if (value.match(isoDateRegexp())) {
            /* iso date format which appeared to be a string */
            return new Date(value);
        }
    }
    return value;
};
exports.parseDate = parseDate;
const jsonBodyReviver = (key, value) => {
    return (0, exports.parseDate)(value, isoDateRegexp());
};
exports.jsonBodyReviver = jsonBodyReviver;
const getTimezoneOffset = () => {
    return new Date().getTimezoneOffset();
};
exports.getTimezoneOffset = getTimezoneOffset;
const convertDatesAccordingToTimezones = (fields, doc, defaultTimeZone) => {
    for (const field of fields) {
        const offset = doc.ts_offset !== undefined ? doc.ts_offset : defaultTimeZone;
        if (doc[field]) {
            doc[field] = new Date(doc[field].getTime() - (offset * 60 * 1000));
        }
    }
    return doc;
};
exports.convertDatesAccordingToTimezones = convertDatesAccordingToTimezones;
