import {DialerCollections} from "@aatdev/dialer-types"
import i18n from "i18next";

export class StringWritable {
    data: string = '';

    constructor(private cb: (data: any) => void) {
    }

    write = (chunk: any, enc: any) => {
        this.data += chunk;
    }
    end = () => {
        this.cb(JSON.parse(this.data));
    }

    on() {
    }

    once() {
    }

    emit() {
    }
}

export const getCollectionItems = () => {
    const ignored = [
        DialerCollections.work_calendar,
        DialerCollections.uploaded_list,
        DialerCollections.uploaded_list_phone,
        DialerCollections.timezone,
        DialerCollections.uploaded_list_phone,
        DialerCollections.call
    ];
    return Object.values(DialerCollections)
        .filter(e => ignored.findIndex(i => i === e) === -1)
        .map(e => ({
            name: e,
            label: i18n.t(`tables:${e}.title`)
        }));
}
