import {DialerCollections} from "@aatdev/dialer-types";
import DateFnsUtils from "@date-io/date-fns";
import {
    Button,
    ButtonGroup,
    ClickAwayListener,
    Fade,
    FormControlLabel,
    Grid,
    Paper,
    Popper,
    Radio,
    RadioGroup,
    Typography
} from "@material-ui/core";
import {MuiThemeProvider} from "@material-ui/core/styles";
import {DatePicker} from "@material-ui/pickers/DatePicker";
import MuiPickersUtilsProvider from "@material-ui/pickers/MuiPickersUtilsProvider";
import {TFunction} from "i18next";
import moment from "moment";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {range} from "rrule/dist/esm/src/helpers";
import {useTableData} from "../../../data/UseQuery";
import {doFreeQueryThunk, updateOneTableItemThunk} from "../../../store/actions/DataActions";
import {getDateFsLocale} from "../../../utils/DateUtils";
import {LightTooltip} from "../../Utils/LightTooltip";
import {CalendarDaysByKey, CalendarDayType} from "../Types/WorkCalendarTypes";
import {calendarDataToMap, getKeyFromDate} from "../Utils/WorkCalendarUtils";
import {materialTheme, useStyles} from './WorkCalendarStyles';

const dayTypes = Object.keys(CalendarDayType);

type Props = {};

const getColor = (t: TFunction, type: string) => t(`forms:work_calendar.day_types.${type}.color`);
const getDayTypeTitle = (t: TFunction, type: string) => t(`forms:work_calendar.day_types.${type}.title`);

const WorkCalendar: FunctionComponent<Props> = (props: Props) => {
    const tableId = DialerCollections.work_calendar;
    const styles = useStyles();
    const {t} = useTranslation();
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [selectedDay, setSelectedDay] = useState<Date | null>(null);
    const dispatch = useDispatch();
    const tableData = useTableData(tableId);

    useEffect(() => {
        dispatch(doFreeQueryThunk(DialerCollections.work_calendar, tableId, {}));
    }, []);

    const calendarData = calendarDataToMap(tableData.items);

    const handleOnSelect = (date: Date, el: HTMLElement | null) => {
        setAnchorEl(el);
        setSelectedDay(date);
    }

    const handleOnChange = async (type: CalendarDayType) => {
        if (selectedDay) {
            const id = getKeyFromDate(selectedDay);
            dispatch(
                updateOneTableItemThunk(
                    DialerCollections.work_calendar,
                    tableId,
                    getKeyFromDate(selectedDay),
                    "type",
                    type,
                    {
                        _id: id,
                        type: type
                    }
                )
            );
            setAnchorEl(null);
        }
    }

    return <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getDateFsLocale()}>
        {anchorEl && tableData?.permissions?.edit && <DayEditor
            open={!!selectedDay}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            dayType={calendarData[getKeyFromDate(selectedDay)]?.type || CalendarDayType.workday}
            onChange={handleOnChange}
        />}

        <MuiThemeProvider theme={materialTheme}>
            <Paper className={styles.root}>
                <Typography variant={"h4"} style={{margin: "1em"}}>
                    {t("forms:work_calendar.title")}
                </Typography>
                <YearSelector selectedYear={selectedYear} onSelect={setSelectedYear}/>
                <div style={{margin: "1em"}}>
                    {dayTypes.filter(type => type !== "reset").map(type => {
                        return <span key={type}>
                            <span className={styles.circle}
                                  style={{backgroundColor: getColor(t, type)}}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <span style={{marginRight: 10}}>{t(`forms:work_calendar.day_types.${type}.title`)}</span>
                        </span>;
                    })}
                </div>
                <Grid container spacing={2} justifyContent={"center"} alignItems={"center"}>
                    {range(0, 12).map(i => <Grid item xs={6} xl={3} key={i}>
                        <MonthCell
                            month={i}
                            year={selectedYear}
                            onSelect={handleOnSelect}
                            calendarData={calendarData}/>
                    </Grid>)}
                </Grid>
            </Paper>
        </MuiThemeProvider>
    </MuiPickersUtilsProvider>;
}

type MonthProps = {
    month: number,
    year: number,
    onSelect: (date: Date, el: HTMLElement | null) => void,
    calendarData: CalendarDaysByKey
}

const MonthCell: FunctionComponent<MonthProps> = (props) => {
    const {month, year, onSelect, calendarData} = props;
    const styles = useStyles();
    const {t} = useTranslation();
    const date = moment().set("year", year).set("month", month).set("hour", 12).toDate();

    return <div className={styles.monthCellContainer}>
        <div className={styles.monthCell}>
            <span className={styles.header}>{t(`forms:work_calendar.months.${month + 1}`)}</span>
            <DatePicker
                size={"small"}
                variant="static"
                openTo="date"
                value={date}
                disableToolbar={true}
                onChange={() => {
                }}
                renderDay={(date, selectedDate, dayInCurrentMonth, dayComponent) => {
                    if (date) {
                        if (dayInCurrentMonth) {
                            const calDay = calendarData[getKeyFromDate(date)];
                            if (calDay) {
                                return <LightTooltip title={getDayTypeTitle(t, calDay.type)}>
                                    <div className={styles.workDay}
                                         style={{
                                             backgroundColor: getColor(t, calDay.type),
                                             outline: !calDay.isOrig ? "2px solid yellow" : ""
                                         }}
                                         onClick={event => onSelect(date, event.currentTarget)}>
                                        {dayComponent}
                                    </div>
                                </LightTooltip>;
                            }
                        }
                        return <div onClick={event => onSelect(date, event.currentTarget)}>{dayComponent}</div>;
                    }
                    return dayComponent;
                }}
            />

        </div>
    </div>;
}

type YearSelectorProps = {
    selectedYear: number,
    onSelect: (year: number) => void
};

const YearSelector: FunctionComponent<YearSelectorProps> = (props) => {
    const {selectedYear, onSelect} = props;
    const currentYear = new Date().getFullYear();
    return <ButtonGroup color={"primary"}>
        {range(currentYear, currentYear + 2).map(year => {
            return <Button
                key={year}
                color={"primary"}
                variant={year === selectedYear ? "contained" : "outlined"}
                onClick={() => onSelect(year)}>
                {year}
            </Button>
        })}
    </ButtonGroup>;
}

type DayEditorProps = {
    open: boolean,
    anchorEl: HTMLElement,
    onClose: (() => void),
    dayType: CalendarDayType,
    onChange: (type: CalendarDayType) => void
}

const DayEditor: FunctionComponent<DayEditorProps> = (props) => {
    const {open, anchorEl, onClose, dayType, onChange} = props;
    const styles = useStyles();
    const {t} = useTranslation();

    return <ClickAwayListener onClickAway={onClose}>
        <Popper open={open} anchorEl={anchorEl} placement={"bottom"} transition>
            {({TransitionProps}) => (
                <Fade {...TransitionProps} timeout={150}>
                    <Paper className={styles.dayEditor}>
                        <RadioGroup aria-label="gender" name="gender1">
                            {dayTypes.map(type => {
                                return <FormControlLabel value={type}
                                                         control={<Radio checked={type === dayType}
                                                                         onClick={() => onChange(type as CalendarDayType)}/>}
                                                         label={t(`forms:work_calendar.day_types.${type}.title`)}/>
                            })}
                        </RadioGroup>
                    </Paper>
                </Fade>
            )}
        </Popper>
    </ClickAwayListener>
}

export default WorkCalendar;
