import {debounce, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import React, {CSSProperties} from 'react';

type TypeFilterValue = {
    name: string;
    operator: string;
    type: string;
    value: string;
    filterEditorProps?: any;
    dataSource?: any;
}

type SelectFilterProps = {
    filterValue?: TypeFilterValue;
    filterDelay?: number;
    onChange?: Function;
    readOnly?: boolean;
    disabled?: boolean;
    style?: CSSProperties;
    nativeScroll?: boolean;
    filterEditorProps?: any;
    rtl?: boolean;
    theme?: string;
    render?: Function;
}

type SelectFilterState = {
    value: string | null;
}

class SelectFilter extends React.Component<SelectFilterProps, SelectFilterState> {
    constructor(props: SelectFilterProps) {
        super(props);

        const {filterValue} = props;

        this.state = {
            value: filterValue ? filterValue.value || null : null,
        };

        this.onChange = this.onChange.bind(this);
        this.onValueChange = this.onValueChange.bind(this);

        if (props.filterDelay && props.filterDelay >= 1) {
            this.onValueChange = debounce(this.onValueChange, props.filterDelay);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: SelectFilterProps) {
        if (
            nextProps.filterValue &&
            nextProps.filterValue.value !== this.state.value
        ) {
            const value = nextProps.filterValue.value;
            this.setValue(value);
        }
    }

    onChange(value: string) {
        this.onValueChange(value);
        this.setValue(value);
    }

    setValue(value: string) {
        this.setState({
            value,
        });
    }

    onValueChange(value: string) {
        this.props.onChange &&
        this.props.onChange({
            ...this.props.filterValue,
            value
        });
    }

    render() {
        let {readOnly, disabled, filterEditorProps} = this.props;
        const items: Array<{ id: string, label: string }> = filterEditorProps?.dataSource || [];
        const label = items.find(i => i.id === this.state.value)?.label || "";
        return this.props.render && this.props.render(
            <Autocomplete
                freeSolo
                disabled={readOnly || disabled}
                options={items.map(e => e.id)}
                getOptionLabel={e => items.find(i => i.id === e)?.label || ""}
                value={this.state.value}
                filterSelectedOptions
                onChange={((event, val) => this.onChange(val || ''))}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size={"small"}
                        variant="standard"
                        title={label}
                        value={label}
                    />
                )}
            />
        );
    }
}

export default SelectFilter;
