"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.expressionOperandsByType = exports.ExpressionOperandType = void 0;
var ExpressionOperandType;
(function (ExpressionOperandType) {
    ExpressionOperandType["eq"] = "eq";
    ExpressionOperandType["neq"] = "neq";
    ExpressionOperandType["gt"] = "gt";
    ExpressionOperandType["lt"] = "lt";
    ExpressionOperandType["gte"] = "gte";
    ExpressionOperandType["lte"] = "lte";
    ExpressionOperandType["empty"] = "empty";
    ExpressionOperandType["notEmpty"] = "notEmpty";
    ExpressionOperandType["startsWith"] = "startsWith";
})(ExpressionOperandType = exports.ExpressionOperandType || (exports.ExpressionOperandType = {}));
var arg = function (value, hasArg) {
    if (hasArg === void 0) { hasArg = true; }
    return ({
        value: value,
        hasArg: hasArg
    });
};
exports.expressionOperandsByType = {
    number: [arg(ExpressionOperandType.eq), arg(ExpressionOperandType.neq),
        arg(ExpressionOperandType.gt), arg(ExpressionOperandType.lt),
        arg(ExpressionOperandType.gte), arg(ExpressionOperandType.lte),
        arg(ExpressionOperandType.empty, false),
        arg(ExpressionOperandType.notEmpty, false)],
    string: [arg(ExpressionOperandType.eq), arg(ExpressionOperandType.neq),
        arg(ExpressionOperandType.empty, false),
        arg(ExpressionOperandType.notEmpty, false),
        arg(ExpressionOperandType.startsWith)],
    phone: [arg(ExpressionOperandType.eq), arg(ExpressionOperandType.neq),
        arg(ExpressionOperandType.empty, false),
        arg(ExpressionOperandType.notEmpty, false),
        arg(ExpressionOperandType.startsWith)],
    timezone: [arg(ExpressionOperandType.eq), arg(ExpressionOperandType.neq)],
    boolean: [arg(ExpressionOperandType.eq), arg(ExpressionOperandType.neq)]
};
