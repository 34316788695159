import {DBElement} from "@aatdev/common-types";
import {
    DialerCallingListModel,
    DialerCollections,
    DialerUploadedListModel,
    DialerUploadedListPhoneModel
} from "@aatdev/dialer-types";
import {FunctionComponent} from "react";
import PreconfiguredDataTableChain from "../../../CommonDataTable/PreconfiguredDataTable/PreconfiguredDataTableChain";
import {getTitle} from "../../../CommonDataTable/PreconfiguredDataTable/PreconfiguredDataTableUtils";
import CallListTable from "../CallListTable";
import UploadedListTable from "../UploadedListTable";
import UploadedPhoneListTable from "../UploadedPhoneListTable";

type Props = {
    callingList: DialerCallingListModel,
    prefixTableId: string
};

const UploadedListChain: FunctionComponent<Props> = (props) => {
    const {callingList, prefixTableId} = props;

    return <PreconfiguredDataTableChain
        title={getTitle(DialerCollections.calling_list, callingList.name)}
        component={UploadedListTable}
        menuTableId={prefixTableId}
        props={{
            tableId: `${DialerCollections.calling_list}:${callingList._id}`,
            editable: true,
            params: {
                callingList
            }
        }}
        onNext={(uploadedList: DBElement) => {
            const ul = uploadedList as DialerUploadedListModel;
            return {
                title: getTitle(DialerCollections.uploaded_list, ul.name),
                component: UploadedPhoneListTable,
                menuTableId: prefixTableId,
                props: {
                    tableId: `${DialerCollections.uploaded_list}:${uploadedList._id}`,
                    params: {
                        uploadedList
                    }
                },
                onNext: (el: DBElement) => {
                    const contact = el as DialerUploadedListPhoneModel;
                    return {
                        title: getTitle(DialerCollections.call, el.name),
                        component: CallListTable,
                        menuTableId: prefixTableId,
                        props: {
                            tableId: `${DialerCollections.call}:${uploadedList._id}:${contact._id}`,
                            params: {
                                contact
                            }
                        },
                    }
                }
            }
        }}
    />
}

export default UploadedListChain;
