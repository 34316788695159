"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DialerCallingListSourceTypes = exports.DialerStructureItemSortDirection = exports.DialerStructureItemType = void 0;
var DialerStructureItemType;
(function (DialerStructureItemType) {
    DialerStructureItemType["string"] = "string";
    DialerStructureItemType["number"] = "number";
    DialerStructureItemType["phone"] = "phone";
    DialerStructureItemType["timezone"] = "timezone";
    DialerStructureItemType["contactId"] = "contactId";
})(DialerStructureItemType = exports.DialerStructureItemType || (exports.DialerStructureItemType = {}));
var DialerStructureItemSortDirection;
(function (DialerStructureItemSortDirection) {
    DialerStructureItemSortDirection["asc"] = "asc";
    DialerStructureItemSortDirection["desc"] = "desc";
})(DialerStructureItemSortDirection = exports.DialerStructureItemSortDirection || (exports.DialerStructureItemSortDirection = {}));
var DialerCallingListSourceTypes;
(function (DialerCallingListSourceTypes) {
    DialerCallingListSourceTypes["directory"] = "directory";
    DialerCallingListSourceTypes["odbc"] = "odbc";
})(DialerCallingListSourceTypes = exports.DialerCallingListSourceTypes || (exports.DialerCallingListSourceTypes = {}));
