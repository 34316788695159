import {AppointmentForm as AppointmentFormBase} from "@devexpress/dx-react-scheduler";
import {FunctionComponent} from "react";
import {AppointmentForm} from "@devexpress/dx-react-scheduler-material-ui";
import {useTranslation} from "react-i18next";

type FormProps = {} & AppointmentFormBase.BasicLayoutProps;

export const ScheduleBasicLayout: FunctionComponent<FormProps> = (props) => {
    const {onFieldChange, appointmentData, ...restProps} = props;
    const {t} = useTranslation();
    const onCustomFieldChange = (type: string) => (nextValue: any) => {
        onFieldChange({[type]: nextValue});
    };

    return (
        <AppointmentForm.BasicLayout appointmentData={appointmentData} onFieldChange={onFieldChange} {...restProps}>
            {['skipWeekends', 'skipPreholiday', 'skipNowork'].map(type => <AppointmentForm.BooleanEditor key={type}
                                                                                                         value={!!appointmentData[type]}
                                                                                                         onValueChange={onCustomFieldChange(type)}
                                                                                                         readOnly={props.readOnly}
                                                                                                         label={t(`common:schedule.form.${type}`)}
            />)}
        </AppointmentForm.BasicLayout>
    )
}
