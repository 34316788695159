import {DBElementPermissions, UIModelAction, UIModelSchema} from "@aatdev/common-types";
import {
    Button,
    Checkbox,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Theme
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {backend} from "../../../../data/Backend";
import {EditingElement} from "../../../../store/actions/DataActionTypes";
import {clearStateVariable, setStateVariable} from "../../../../store/actions/GeneralActions";
import {AppState} from "../../../../store/StoreTypes";
import {downloadFile} from "../../../../utils/MiscUtils";
import {notify} from "../../../../utils/NotifierHelpers";
import {CustomTableActionType} from "../../../CommonDataTable/CommonDataTableTypes";
import {actionUtils} from "../../../Utils/ActionUtils";
import {getCollectionItems} from "./Utils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        },
        dialog: {
            minWidth: 400
        },
        content: {
            display: 'flex',
            flexDirection: 'column'
        },
        mode: {
            minWidth: 300,
            padding: 10
        }
    }),
);

const action = (action: UIModelAction, element: EditingElement, schema: UIModelSchema, permissions: DBElementPermissions): CustomTableActionType => {
    return {
        buttonPosition(): "left" | "right" {
            return 'right';
        },
        renderButton: () => {
            return () => {
                const dispatch = useDispatch();
                useEffect(() => {
                    return () => {
                        dispatch(clearStateVariable(action.action));
                    };
                }, [element]);
                return <IconButton color={"primary"}
                                   onClick={() => dispatch(setStateVariable(action.action, true))}
                                   disabled={!element?.data?._id && action.enabled_on_select}
                                   title={action.title}>
                    {actionUtils(action.icon)}
                </IconButton>
            }
        },
        renderCode: () => {
            return () => {
                const classes = useStyles();
                const dispatch = useDispatch();
                const {t} = useTranslation();
                const open = useSelector((state: AppState) => !!state?.general?.variables?.[action.action]);
                const [selected, setSelected] = useState<string[]>([]);
                const collections = getCollectionItems();

                const handleSelect = (item: any) => () => {
                    const currentIndex = selected.indexOf(item.name);
                    const newChecked = [...selected];

                    if (currentIndex === -1) {
                        newChecked.push(item.name);
                    } else {
                        newChecked.splice(currentIndex, 1);
                    }
                    setSelected(newChecked);
                }

                const renderItem = (item: any) =>
                    <ListItem key={item.name} role={undefined} dense button onClick={handleSelect(item)}>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={selected.indexOf(item.name) !== -1}
                                tabIndex={-1}
                                disableRipple
                            />
                            <ListItemText id={item.name} primary={item.label}/>
                        </ListItemIcon>
                    </ListItem>;

                const handleClose = () => {
                    dispatch(clearStateVariable(action.action));
                }

                const handleExport = async () => {
                    try {
                        const res = await backend.exportProject(element?.data?._id, selected);
                        downloadFile(res.data, `${element?.data?.name}.zip`);
                    } catch (e) {
                        notify(dispatch, `Ошибка ${e}`, "error");
                    } finally {
                        handleClose();
                    }
                }

                return <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>{t('tables:project.actions.export.title')}</DialogTitle>
                    <DialogContent>
                        <List className={classes.root}>
                            {collections.map(item => renderItem(item))}
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="secondary" autoFocus>
                            {t('tables:project.actions.export.buttons.cancel')}
                        </Button>
                        <Button onClick={() => handleExport()} color="primary"
                                disabled={selected.length === 0}>
                            {t('tables:project.actions.export.buttons.export')}
                        </Button>
                    </DialogActions>
                </Dialog>
            };
        }
    }
}

export default action;
