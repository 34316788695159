import {HelpModel} from "@aatdev/common-types";
import {createStyles, IconButton, makeStyles, Theme} from "@material-ui/core";
import {HelpOutline} from "@material-ui/icons";
import React, {FunctionComponent, MouseEvent, useState} from "react";
import {useSelector} from "react-redux";
import {backend} from "../../data/Backend";
import {AppState} from "../../store/StoreTypes";
import {buildHelpId} from "../Utils/HelpUtils";
import HelpContentDialog from "./HelpContentDialog";

type Props = {
    label?: string,
    type: 'popup' | 'window',
    helpId: string[],
    header?: string
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    button: {
        display: 'inline',
    },
    icon: {
        backgroundColor: 'white',
        color: 'black'
    },
    popover: {
        pointerEvents: 'none',
    },
    popoverPaper: {
        padding: theme.spacing(1),
    },
}));

const HelpButton: FunctionComponent<Props> = (props) => {
    const {helpId = [], label, header} = props;
    const styles = useStyles();
    const [helpData, setHelpData] = useState<HelpModel | null>(null);
    const [openDialog, setOpenDialog] = useState<any>(false);
    const user = useSelector((state: AppState) => state.general.user);

    const getHelpData = () => {
        backend.getHelpData(buildHelpId(helpId)).then(res => {
            setHelpData(res.data);
        });
    }

    const handleClick = (event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        setOpenDialog(true);
        getHelpData();
    }
    const getHelpButton = () => {
        return <>
            {helpData && <HelpContentDialog helpData={helpData}
                                            open={openDialog}
                                            onClose={() => setOpenDialog(false)}
                                            editable={!!user?.isSuperAdmin}
                                            defaultHeader={header}
            />}

            <IconButton className={styles.button} onClick={handleClick}>
                <HelpOutline/>
            </IconButton>
        </>
    }

    if (label) {
        return <span style={{whiteSpace: "nowrap"}}>{label} {getHelpButton()}
        </span>
    } else {
        return getHelpButton();
    }
}

export default HelpButton;
