import {HelpModel} from "@aatdev/common-types";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import _ from "lodash";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import Markdown from "../Utils/Markdown";
import EditableElement from "./EditableElement";


type Props = {
    open: boolean,
    onClose: (yes: boolean) => void,
    helpData: HelpModel,
    editable: boolean,
    defaultHeader?: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            minWidth: 1000
        }
    })
);

const renderChildrenHelp = (help: HelpModel[]): string => {
    return help.map(d => {
        return `
${_.range(0, d.help_id.split('.').length).map(() => '#').join('')} ${d.header}        
${d.content}
${renderChildrenHelp((d.children as any[]) || [])}
`
    }).join('\n');
}

const HelpContentDialog: FunctionComponent<Props> = (props: Props) => {
    const {open, onClose, helpData, editable, defaultHeader} = props;
    const styles = useStyles();
    const help = helpData as any;
    const {t} = useTranslation();
    return (<>
            <Dialog open={open}
                    onClose={() => onClose(false)}
                    maxWidth="xl"
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}>
                <DialogTitle>
                    <EditableElement content={help.header || defaultHeader || 'Header'} editable={editable}
                                     helpId={helpData.help_id}/>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className={styles.content}>
                        <EditableElement content={help.content || 'Content'} editable={editable} multiline={true}
                                         helpId={helpData.help_id}/>
                    </DialogContentText>
                    <DialogContentText className={styles.content}>
                        <Markdown>{renderChildrenHelp(help.children)}</Markdown>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => onClose(false)} color="primary">
                        {t("common:buttons.close")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default HelpContentDialog;
