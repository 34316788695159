"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.enumFromStringValue = exports.normalizeLanguageCode = void 0;
const normalizeLanguageCode = (lang) => {
    lang = lang.toLowerCase();
    if (lang === 'en')
        lang = 'en-us';
    if (lang === 'ru')
        lang = 'ru-ru';
    return lang;
};
exports.normalizeLanguageCode = normalizeLanguageCode;
function enumFromStringValue(enm, value, def) {
    return Object.values(enm).includes(value)
        ? value
        : def;
}
exports.enumFromStringValue = enumFromStringValue;
