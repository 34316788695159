import {IconButton, TextField} from "@material-ui/core";
import {SaveOutlined} from "@material-ui/icons";
import React, {FunctionComponent, useCallback, useState} from "react";
import {backend} from "../../data/Backend";
import Markdown from "../Utils/Markdown";

type Props = {
    helpId: string,
    content: string,
    editable: boolean,
    multiline?: boolean
}

const EditableElement: FunctionComponent<Props> = (props: Props) => {
    const {editable, content, multiline, helpId} = props;
    const [editMode, setEditMode] = useState(false);
    const [currentContent, setCurrentContent] = useState(content);

    const handleSave = (evt: React.MouseEvent<HTMLElement>) => {
        evt.stopPropagation();
        evt.preventDefault();
        setEditMode(false);
        backend.saveHelpData(helpId, multiline ? 'content' : 'header', currentContent).then();
    }
    const getViewElement = () => {
        return multiline ? <Markdown>{currentContent}</Markdown> : <span>{currentContent}</span>;
    }

    const autoFocus = useCallback((inputElement) => {
        if (inputElement) {
            inputElement.focus();
        }
    }, []);

    return <div onClick={() => setEditMode(true)}>
        {
            !editMode || !editable ? getViewElement() :
                <div style={{position: "relative"}}>
                    <IconButton
                        size={"small"}
                        onClick={handleSave}
                        color={"secondary"}
                        style={{position: "absolute", right: 10, top: 0, zIndex: 999}}>
                        <SaveOutlined/>
                    </IconButton>
                    <TextField
                        multiline={multiline}
                        value={currentContent}
                        fullWidth
                        onChange={event => setCurrentContent(event.target.value)}
                        inputRef={autoFocus}
                        onKeyDown={event => event.stopPropagation()}
                    />
                </div>
        }
    </div>;
}

export default EditableElement;
