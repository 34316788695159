import {ValidatedValue} from "@aatdev/common-types";
import {makeStyles} from "@material-ui/core";
import React, {FunctionComponent} from 'react';
import {backend} from "../../../data/Backend";
import {isFieldVisible} from "../../../utils/ModelUtils";
import {deleteValidatedData, isMainTab} from "../../../utils/ValidatedValueUtils";
import {DataFormProps} from "./DataFormTypes";
import DataInput from "./DataInput";
import {getConstrain, validateAgainstConstraint} from "./DataInputConstrains";

const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column"
    },
    input: {
        marginBottom: "1em"
    }
});

const DataForm: FunctionComponent<DataFormProps> = (props) => {
    const {schema, onChange, fieldPrefix, element} = props;
    const {data, isNew, uiModel, permissions} = element;
    const styles = useStyles();
    const tabData = isMainTab(fieldPrefix) ? data : data[fieldPrefix] || {};

    const handleChange = (fieldName: string) => async (value: ValidatedValue<any>) => {
        const field = schema.fields.find(e => e.field === fieldName);

        if (!field) {
            return;
        }

        const values: Record<string, ValidatedValue<any>> = {
            [fieldName]: {
                value: value.value,
                ___valid___: true,
                messages: []
            }
        }

        if (field?.unique) {
            const res = await backend.checkUnique(uiModel.meta.collection, data._id?.value, fieldName, value?.value);
            if (res.data) {
                if (!res.data.unique) {
                    values[fieldName].___valid___ = false;
                    values[fieldName].messages?.push("not_unique");
                }
            }
        }

        if (field.external_validation) {
            const validRes = await backend.validate(uiModel.meta.collection, deleteValidatedData({
                ...tabData,
                [fieldName]: value.value || ""
            })) || {};
            Object.keys(validRes).forEach(key => {
                const item = values[key] !== undefined ? values[key] : tabData[key];
                values[key] = {
                    ...(item),
                    ___valid___: validRes[key] === true,
                    messages: validRes[key] !== true ? [validRes[key]] : []
                }
            });
        }

        // we validate data in the form in order to run form based validation function
        const validated = validateAgainstConstraint(value.value, getConstrain(field?.validator));
        values[fieldName].___valid___ = values[fieldName].___valid___ && validated.valid;
        if (!validated.valid && validated.messages) {
            validated.messages.forEach(e => values[fieldName].messages?.push(e));
        }

        onChange(values);
    }

    const resolveValue = (field: string, data: any): any => {
        const ff = field.split('.');
        if (ff.length > 1) {
            const f = ff.shift();
            if (f) {
                return resolveValue(ff.join('.'), data[f]);
            }
        }
        return data[field];
    }

    const dataWithoutValidation = deleteValidatedData(data);

    return <div className={styles.container}>
        {schema.fields
            .filter(f => !f.show_conditions || isFieldVisible(dataWithoutValidation, f))
            .map((field) => {
                return <div key={field.field} className={styles.input}>
                    <DataInput
                        value={field.type === "widget" ? data : resolveValue(field.field, tabData)}
                        constrains={field.validator}
                        config={field}
                        isNew={isNew}
                        fullWidth={true}
                        disabled={(isNew && !permissions.create) || (!isNew && !permissions.edit)}
                        onChange={handleChange(field.field)}
                        externalValidation={true}
                        helpId={[uiModel.meta.collection, fieldPrefix, field.field]}
                    />
                </div>
            })}
    </div>;
};

export default DataForm;
