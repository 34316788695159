import {FunctionComponent} from "react";
import classNames from "clsx";
import {styled} from "@material-ui/core";
import {blue} from "@material-ui/core/colors";
import {getKeyFromDate} from "../../Utils/WorkCalendarUtils";
import {CalendarDayType} from "../../Types/WorkCalendarTypes";

type Props = any;

const PREFIX = 'Appointment';

export const classes = {
    appointment: `${PREFIX}-appointment`,
    clickableAppointment: `${PREFIX}-clickableAppointment`,
    shadedAppointment: `${PREFIX}-shadedAppointment`,
};

export const PRIMARY_COLOR: any = blue;

export const ensureColor = (level: any, color: any) => (color[level] || PRIMARY_COLOR[level]);

export const getResourceColor = (resources: any) => {
    if (resources && resources.length) {
        return resources.find((resource: any) => resource.isMain)?.color;
    }
    return undefined;
};

export const getAppointmentColor = (level: any, color: any, defaultColor: any) => {
    if (!color) return ensureColor(level, defaultColor);
    if (typeof color === 'string') return color;
    return ensureColor(level, color);
};

const StyledDiv = styled('div')(({
                                     theme: {palette, typography, spacing}, resources,
                                 }: any) => ({
    [`&.${classes.appointment}`]: {
        userSelect: 'none',
        position: 'absolute',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        boxSizing: 'border-box',
        border: `1px solid ${palette.background.paper}`,
        backgroundClip: 'padding-box',
        borderRadius: spacing(0.5),
        backgroundColor: getAppointmentColor(
            300, getResourceColor(resources), palette.primary,
        ),
        ...typography.caption,
        '&:hover': {
            backgroundColor: getAppointmentColor(
                400, getResourceColor(resources), palette.primary,
            ),
        },
        '&:focus': {
            backgroundColor: getAppointmentColor(
                100, getResourceColor(resources), palette.primary,
            ),
            outline: 0,
        },
    },
    [`&.${classes.clickableAppointment}`]: {
        cursor: 'pointer',
    },
    [`&.${classes.shadedAppointment}`]: {
        backgroundColor: getAppointmentColor(
            200, getResourceColor(resources), palette.primary,
        ),
        '&:hover': {
            backgroundColor: getAppointmentColor(
                300, getResourceColor(resources), palette.primary,
            ),
        },
    },
}));

export const AppointmentComponent: FunctionComponent<Props> = (props) => {
    const {
        className,
        children,
        data,
        onClick: handleClick,
        draggable,
        isShaded,
        resources,
        forwardedRef,
        calendarData,
        ...restProps
    } = props;

    const {startDate} = data;
    const dayKey = getKeyFromDate(startDate);
    const dayType: CalendarDayType = calendarData[dayKey]?.type;

    const isSkip = (CalendarDayType.preholiday === dayType && data.skipPreholiday) ||
        (CalendarDayType.nowork === dayType && data.skipNowork) ||
        (CalendarDayType.weekend === dayType && data.skipWeekends);

    const onClick = handleClick
        ? {
            onClick: ({target}: any) => {
                handleClick({target, data});
            },
        }
        : null;

    const clickable = onClick || restProps.onDoubleClick || draggable;
    return !isSkip ? <StyledDiv
        resources={resources}
        ref={forwardedRef}
        className={classNames({
            [classes.appointment]: true,
            [classes.clickableAppointment]: clickable,
            [classes.shadedAppointment]: isShaded,
        }, className)}
        {...onClick}
        {...restProps}>
        {children}
    </StyledDiv> : null;
};
