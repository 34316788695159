import {UIModel} from "@aatdev/common-types";
import {v4} from "uuid";
import DataFormTabs from "../components/DataEditor/DataForm/DataMenuFormTabs";
import {DataMenuItem, EditingElement, TableSubMenuItem} from "../store/actions/DataActionTypes";

export const createSubMenuConfig = (rootMenu: DataMenuItem, element: EditingElement, model: UIModel): TableSubMenuItem => {
    return {
        type: 'sub',
        id: element.id,
        parentId: rootMenu.uiModel.meta.tableId,
        elementId: element.id,
        title: '',
        uiModel: {
            meta: {
                ...model.meta,
                tableId: v4().toString()
            },
            schema: model.schema
        },
        path: getRoutePath(model.meta.tableId, element.id),
        component: DataFormTabs
    };
}

export const getRoutePath = (tableId: string, id?: string) => {
    if (id) {
        return `/${tableId}/${id}`;
    }
    return `/${tableId}`;
}
