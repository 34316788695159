import {DialerCampaignModel} from "@aatdev/dialer-types";
import {Paper, TableContainer} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {FunctionComponent} from "react";
import {useSelector} from "react-redux";
import {AppState} from "../../../store/StoreTypes";
import {deleteValidatedData} from "../../../utils/ValidatedValueUtils";
import {DataFormProps} from "../../DataEditor/DataForm/DataFormTypes";
import DashboardCampaignCard from "../Dashboard/DashboardCampaignCard";

type Props = {} & DataFormProps;

const CampaignStat: FunctionComponent<Props> = (props) => {
    const classes = useStyles();
    const {data} = props.element;
    const campaign: DialerCampaignModel = deleteValidatedData(data);
    const dialerStat = useSelector((state: AppState) => state.stats.campaignStat[campaign._id]) || {
        campaignId: campaign._id,
        campaignName: campaign.name,
        usedOutTrunks: 0,
        usedAgentTrunks: 0,
        total: campaign.current_counters,
        last30: {}
    };

    return <TableContainer component={Paper} className={classes.table}>
        <DashboardCampaignCard campaignName={campaign.name} data={dialerStat} hideHeader={true}/>
    </TableContainer>;
}

const useStyles = makeStyles({
    table: {
        maxWidth: 1000,
        margin: "auto"
    },
});

export default CampaignStat;
