import {LoggedUser} from "@aatdev/common-types";
import {GeneralActionType} from "../actions/GeneralActionTypes";
import {GeneralAction} from "../StoreTypes";

export interface GeneralAppAction<T> extends GeneralAction {
    payload: T
}

export type GeneralState = {
    loading?: {
        isActive: boolean,
        message?: string
    },
    user?: LoggedUser,
    variables?: Record<string, any>
}

const changeLoggedState = (state: GeneralState, action: GeneralAppAction<LoggedUser>) => {
    const {payload: user} = action;
    return {
        ...state,
        user: user
    }
}

const setVariable = (state: GeneralState, action: GeneralAppAction<{ name: string, value: any }>) => {
    const {payload} = action;
    return {
        ...state,
        variables: {
            ...state.variables,
            [payload.name]: payload.value
        }
    }
}

const clearVariable = (state: GeneralState, action: GeneralAppAction<string>) => {
    const {payload} = action;
    const variables = {
        ...state.variables
    }
    delete variables[payload];
    return {
        ...state,
        variables
    }
}

const startLoading = (state: GeneralState, action: GeneralAppAction<string>): GeneralState => {
    return {
        ...state,
        loading: {
            isActive: true,
            message: action.payload
        }
    }
}

const stopLoading = (state: GeneralState): GeneralState => {
    return {
        ...state,
        loading: {
            isActive: false,
            message: undefined
        }
    }
}

export const generalReducer = (state: GeneralState = {}, action: GeneralAppAction<any>) => {
    switch (action.type) {
        case GeneralActionType.LOGGED_STATE:
            return changeLoggedState(state, action);
        case GeneralActionType.SET_VARIABLE:
            return setVariable(state, action);
        case GeneralActionType.CLEAR_VARIABLE:
            return clearVariable(state, action);
        case GeneralActionType.START_LOADING:
            return startLoading(state, action);
        case GeneralActionType.STOP_LOADING:
            return stopLoading(state);
    }
    return state;
}
