import {createStyles, TableCell, Theme, withStyles} from "@material-ui/core";

export default withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.background.default,
            fontWeight: "bold"
        },
        body: {
            fontSize: 14,
        },
    }),
)(TableCell);
