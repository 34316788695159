import {MuiThemeProvider} from "@material-ui/core";
import i18n from "i18next";
import Cookies from 'js-cookie';
import {SnackbarProvider} from "notistack";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {ThemeProvider} from "styled-components";
import App from "./App";
import {WhiteLabelContext} from "./components/Utils/WhiteLabelProvider";
import {backend} from "./data/Backend";
import {init18n} from "./i18n";
import {store} from "./store/RootStore";
import {getMuiTheme} from "./Theme";

init18n()
    .then(() => {
        return backend.getTheme();
    })
    .then((whiteLabel) => {
        if (i18n.language) {
            Cookies.set("userLanguage", i18n.language);
        }
        i18n.on("languageChanged", lng => {
            Cookies.set("userLanguage", lng);
        });
        ReactDOM.render(<Provider store={store}>
            <SnackbarProvider maxSnack={7} anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }} preventDuplicate>
                <BrowserRouter>
                    <MuiThemeProvider theme={getMuiTheme(whiteLabel)}>
                        <ThemeProvider theme={getMuiTheme(whiteLabel)}>
                            <WhiteLabelContext.Provider value={whiteLabel}>
                                <App/>
                            </WhiteLabelContext.Provider>
                        </ThemeProvider>
                    </MuiThemeProvider>
                </BrowserRouter>
            </SnackbarProvider>
        </Provider>, document.getElementById('root'))
    });

