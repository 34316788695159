import {DBElementPermissions, UIModelAction, UIModelSchema} from "@aatdev/common-types";
import {DialerCampaignModel} from "@aatdev/dialer-types";
import {Button, Typography} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {PauseOutlined, PlayArrowOutlined} from "@material-ui/icons";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {updateOneTableItemThunk} from "../../../../store/actions/DataActions";
import {EditingElement} from "../../../../store/actions/DataActionTypes";
import {clearStateVariable} from "../../../../store/actions/GeneralActions";
import {CustomTableActionType} from "../../../CommonDataTable/CommonDataTableTypes";

const action = (action: UIModelAction, element: EditingElement, schema: UIModelSchema, permissions: DBElementPermissions): CustomTableActionType => {
    return {
        buttonPosition(): "left" | "right" {
            return 'left';
        },
        renderButton: () => {
            return () => {
                const {t} = useTranslation("tables", {
                    keyPrefix: "campaign.tabs.main.actions.player"
                });
                const dispatch = useDispatch();
                const classes = useStyles();
                useEffect(() => {
                    return () => {
                        dispatch(clearStateVariable(action.action));
                    };
                }, [element]);

                const toggleEnable = () => {
                    dispatch(updateOneTableItemThunk(element.uiModel.meta.collection, element.uiModel.meta.tableId, campaign._id, "enabled", !campaign.enabled));
                }

                const campaign: DialerCampaignModel = element.data;
                const getButtons = () => {
                    if (!campaign.pass_limits) {
                        return <span className={classes.info}>{t("set_limits")}</span>
                    }
                    if (!campaign.pass_schedule) {
                        return <span className={classes.info}>{t("set_schedule")}</span>
                    }
                    return campaign.enabled ? <Button color={"secondary"}
                                                      variant={"outlined"}
                                                      onClick={toggleEnable}
                                                      disabled={!campaign?._id && action.enabled_on_select}
                                                      title={action.title} startIcon={<PauseOutlined/>}>
                            {t("stop")}
                        </Button> :
                        <Button color={"primary"}
                                variant={"outlined"}
                                onClick={toggleEnable}
                                disabled={!campaign?._id && action.enabled_on_select}
                                title={action.title} startIcon={<PlayArrowOutlined/>}>
                            {t("start")}
                        </Button>;
                }
                if (campaign?._id) {
                    return <span style={{display: "flex", alignItems: "center"}}>
                        <Typography style={{marginLeft: "1em"}}>{t("title")}</Typography>
                        {getButtons()}
                </span>
                }
                return null;
            }
        },
        renderCode: () => {
            return () => {
                return <span/>
            };
        }
    }
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        info: {
            color: theme.palette.error.light,
            marginLeft: "1em",
            fontWeight: "bold"
        }
    })
);

export default action;
