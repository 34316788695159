"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UIModelFieldType = void 0;
var UIModelFieldType;
(function (UIModelFieldType) {
    UIModelFieldType["date"] = "date";
    UIModelFieldType["time"] = "time";
    UIModelFieldType["number"] = "number";
    UIModelFieldType["int"] = "int";
    UIModelFieldType["boolean"] = "boolean";
    UIModelFieldType["text"] = "text";
    UIModelFieldType["entity"] = "entity";
    UIModelFieldType["select"] = "select";
    UIModelFieldType["password"] = "password";
    UIModelFieldType["widget"] = "widget";
})(UIModelFieldType = exports.UIModelFieldType || (exports.UIModelFieldType = {}));
