import {fixedT} from "../../i18n";

const t = fixedT();

const keys = ['pageText',
    'ofText',
    'perPageText',
    'showingText',
    'clearAll',
    'clear',
    'showFilteringRow',
    'hideFilteringRow',
    'enable',
    'disable',
    'sortAsc',
    'sortDesc',
    'unsort',
    'group',
    'ungroup',
    'lockStart',
    'lockEnd',
    'unlock',
    'columns',
    'contains',
    'startsWith',
    'endsWith',
    'notContains',
    'inlist',
    'notinlist',
    'neq',
    'inrange',
    'notinrange',
    'eq',
    'notEmpty',
    'empty',
    'lt',
    'lte',
    'gt',
    'gte',
    'before',
    'beforeOrOn',
    'afterOrOn',
    'after',
    'start',
    'end',
    'dragHeaderToGroup',
    'noRecords',
    'calendar.todayButtonText',
    'calendar.clearButtonText',
    'calendar.okButtonText',
    'calendar.cancelButtonText',
    'perPageText',
    'pageText',
    'ofText',
    'showingText',
    'perPageText',
    'pageText',
    'ofText',
    'showingText'];

export const getTranslations = () => keys.reduce((pv: Record<string, string>, cv) => {
    pv[cv] = t(`common:table.${cv}`);
    return pv;
}, {});
